import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import { generatePath } from 'react-router-dom';
import { connect } from 'react-redux';
import { highlightAdd } from 'actions/highlight';
import { config } from 'configuration';
import { HIGHLIGHT_COLORS } from 'consts';
import { withReporting } from 'provider/reporting';
import { withCreateBookmark, withDeleteBookmark } from 'graphql/bookmarks';
import { withEventDetails } from 'graphql/audioCalls';
import { withUrlContext } from 'hoc/url';
import { copyToClipboard, generateModalId, generateTabId, get } from 'utils';
import { externalRoutes } from 'routes';
import { withCreateTranscrippet } from 'graphql/transcrippets';
import { withData } from './data';
import { EventActionMenuUI } from './ui';

export class EventActionMenu extends PureComponent {
    static displayName = 'EventActionMenuContainer';

    static propTypes = {
        addHighlight: PropTypes.func.isRequired,
        bookmarkId: PropTypes.string,
        createBookmark: PropTypes.func.isRequired,
        createTabPath: PropTypes.func.isRequired,
        createTranscrippet: PropTypes.func.isRequired,
        deleteBookmark: PropTypes.func.isRequired,
        doNotRender: PropTypes.bool,
        eventId: PropTypes.string,
        eventItemId: PropTypes.string,
        eventType: PropTypes.string,
        flag: PropTypes.func,
        flagEnabled: PropTypes.bool,
        hideTooltip: PropTypes.func,
        highlight: PropTypes.string,
        highlightsFilterKey: PropTypes.string,
        highlightsSortKey: PropTypes.string,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        isEventOwner: PropTypes.bool,
        isPublished: PropTypes.bool,
        onDeleteTranscript: PropTypes.func,
        onEditTranscript: PropTypes.func,
        onHide: PropTypes.func,
        onHighlight: PropTypes.func,
        onShow: PropTypes.func,
        openByDefault: PropTypes.bool,
        pathname: PropTypes.string.isRequired,
        reporter: PropTypes.shape({
            actions: PropTypes.object,
            objects: PropTypes.object,
            track: PropTypes.func
        }).isRequired,
        reverse: PropTypes.bool,
        selectionStartId: PropTypes.string,
        styles: PropTypes.objectOf(PropTypes.any),
        tabs: PropTypes.arrayOf(PropTypes.any),
        transcriptEvents: PropTypes.arrayOf(PropTypes.any),
        useSelection: PropTypes.bool
    };

    static defaultProps = {
        bookmarkId: undefined,
        doNotRender: false,
        eventId: undefined,
        eventItemId: undefined,
        eventType: undefined,
        flag: undefined,
        flagEnabled: false,
        hideTooltip: undefined,
        highlight: undefined,
        highlightsFilterKey: undefined,
        highlightsSortKey: undefined,
        isEventOwner: false,
        isPublished: false,
        onDeleteTranscript: undefined,
        onEditTranscript: undefined,
        onHide: undefined,
        onHighlight: undefined,
        onShow: undefined,
        openByDefault: false,
        reverse: false,
        selectionStartId: null,
        styles: undefined,
        tabs: [],
        transcriptEvents: [],
        useSelection: false
    };

    constructor(props) {
        super(props);

        this.calculateSelectionOffsets = this.calculateSelectionOffsets.bind(this);
        this.copyTextToClipboard = this.copyTextToClipboard.bind(this);
        this.copyLinkToClipboard = this.copyLinkToClipboard.bind(this);
        this.getHighlight = this.getHighlight.bind(this);
        this.getTargets = this.getTargets.bind(this);
        this.onBookmark = this.onBookmark.bind(this);
        this.onTranscrippet = this.onTranscrippet.bind(this);
        this.openBookmarkForm = this.openBookmarkForm.bind(this);
        this.openEditor = this.openEditor.bind(this);
        this.onFlag = this.onFlag.bind(this);

        this.state = {
            copiedLink: false,
            copiedText: false,
            creatingTranscrippet: false,
            flagged: false,
            submitting: false
        };
    }

    componentDidMount() {
        const { openByDefault } = this.props;
        // Customer request
        // on Text selection, copy to clipboard by default
        if (openByDefault) {
            this.setState({ copiedText: true });
            copyToClipboard(get(this.getHighlight(true), 'text', '').replace(/(<([^>]+)>)/gi, ''));
        }
    }

    copyTextToClipboard(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setState({ copiedText: true });
        copyToClipboard(get(this.getHighlight(), 'text', '').replace(/(<([^>]+)>)/gi, ''));
    }

    copyLinkToClipboard(event) {
        const { createTabPath, eventId, eventItemId } = this.props;
        event.preventDefault();
        event.stopPropagation();
        const tabPath = createTabPath(
            generateTabId({
                audioCallId: eventId,
                page: 'text',
                pageId: eventItemId
            })
        );
        this.setState({ copiedLink: true });
        copyToClipboard(`${config.DASHBOARD_ENDPOINT}${tabPath.slice(1)}`);
    }

    calculateSelectionOffsets(selection) {
        const { eventItemId, selectionStartId, reverse } = this.props;
        const range = selection.getRangeAt(0);
        const endRange = range.cloneRange();
        const startRange = range.cloneRange();
        const defaultNode =
            selection.focusNode.parentNode.closest('.transcriptEvent') ||
            selection.baseNode.parentNode.closest('.transcriptEvent');
        let endParentNode = defaultNode;
        let startParentNode = defaultNode;
        if (selectionStartId !== eventItemId) {
            const highlightIsSequential = reverse ? eventItemId < selectionStartId : selectionStartId < eventItemId;
            const focusNode = selection.focusNode.parentNode.closest('.transcriptEvent');
            const baseNode = selection.baseNode.parentNode.closest('.transcriptEvent');
            if (focusNode && baseNode) {
                endParentNode = highlightIsSequential ? focusNode : baseNode;
                startParentNode = highlightIsSequential ? baseNode : focusNode;
            }
        }
        if (endParentNode) {
            endRange.selectNodeContents(endParentNode);
            endRange.setEnd(range.endContainer, range.endOffset);
        }
        if (startParentNode) {
            startRange.selectNodeContents(startParentNode);
            startRange.setEnd(range.startContainer, range.startOffset);
        }

        return {
            startOffset: startParentNode ? startRange.toString().length : 0,
            endOffset: endParentNode ? endRange.toString().length : 0
        };
    }

    getHighlight(keepSelection = false) {
        const { highlight, useSelection, eventItemId, reverse, selectionStartId, transcriptEvents } = this.props;
        const selection = window.getSelection();
        const selectionText = selection.toString();
        let highlightText = highlight?.replace(/<[^>]*>?/gm, '');
        let startOffset;
        let endOffset;

        if (useSelection && selectionText.length > 0) {
            const offsets = this.calculateSelectionOffsets(selection);
            startOffset = get(offsets, 'startOffset');
            endOffset = get(offsets, 'endOffset');
            highlightText = selectionText?.replace(/<[^>]*>?/gm, '');

            if (transcriptEvents && transcriptEvents.length > 0) {
                // Stitch together transcript highlights
                let highlighted = '';
                const startIndex = transcriptEvents.findIndex(t => t.id === selectionStartId);
                const endIndex = transcriptEvents.findIndex(t => t.id === eventItemId);
                const startRange = startIndex < endIndex ? startIndex : endIndex;
                const endRange = startIndex < endIndex ? endIndex : startIndex;
                const eventRange = transcriptEvents.slice(startRange, endRange + 1);
                // Selection within 1 event
                if (eventRange.length === 1) {
                    highlighted = get(eventRange, '[0].transcript', '')
                        .replace(/<[^>]*>?/gm, '')
                        .slice(startOffset, endOffset);
                } else {
                    // Selection across multiple event items
                    (reverse ? eventRange.reverse() : eventRange).forEach(({ transcript }, idx) => {
                        if (idx === 0) {
                            // Grab start event and use offset
                            highlighted = transcript.replace(/<[^>]*>?/gm, '').slice(startOffset);
                        } else if (idx === eventRange.length - 1) {
                            // Grab end event and use end offset
                            const newText = transcript.replace(/<[^>]*>?/gm, '').slice(0, endOffset);
                            highlighted = reverse ? `${newText} ${highlighted}` : `${highlighted} ${newText}`;
                        } else {
                            // Fill middle events
                            highlighted = reverse ? `${transcript} ${highlighted}` : `${highlighted} ${transcript}`;
                        }
                    });
                }

                highlightText = highlighted;
            }
            if (!keepSelection) {
                selection.collapseToEnd();
            }
        }

        return { endOffset, text: highlightText, startOffset, selection };
    }

    getTargets() {
        const { useSelection, reverse, eventItemId, selectionStartId } = this.props;
        const highlightIsSequential = selectionStartId <= eventItemId;
        let targetId = eventItemId;
        let targetEndId;
        // Handle reverse transcript
        // so the server can correctly
        // calculate the highlight
        if (useSelection) {
            if (highlightIsSequential) {
                if (reverse) {
                    targetId = eventItemId;
                    targetEndId = selectionStartId;
                } else {
                    targetId = selectionStartId;
                    targetEndId = eventItemId;
                }
            } else if (reverse) {
                targetId = selectionStartId;
                targetEndId = eventItemId;
            } else {
                targetId = eventItemId;
                targetEndId = selectionStartId;
            }
        }

        return { targetId, targetEndId };
    }

    onBookmark(event, color) {
        event.preventDefault();
        event.stopPropagation();
        const {
            bookmarkId,
            createBookmark,
            deleteBookmark,
            eventId,
            highlightsFilterKey,
            highlightsSortKey,
            onHighlight,
            reporter
        } = this.props;
        if (onHighlight) onHighlight();
        this.setState({ submitting: true }, () => {
            const highlight = this.getHighlight();
            const { targetId, targetEndId } = this.getTargets();
            (bookmarkId
                ? deleteBookmark({
                      filterKey: highlightsFilterKey,
                      sortKey: highlightsSortKey,
                      bookmarkId,
                      targetType: 'transcript',
                      eventId
                  })
                : createBookmark({
                      filterKey: highlightsFilterKey,
                      highlight: highlight.text,
                      highlightColor: color || HIGHLIGHT_COLORS[0],
                      sortKey: highlightsSortKey,
                      targetId,
                      targetEndId,
                      targetOffset: highlight.startOffset,
                      targetEndOffset: highlight.endOffset - 1, // Server wants exact bounds
                      targetType: 'transcript',
                      eventId
                  })
            )
                .then(() => {
                    // Track highlight
                    reporter.track(
                        reporter.actions.submit,
                        bookmarkId ? reporter.objects.highlightDelete : reporter.objects.highlightCreate,
                        {
                            component: 'EventActionMenu',
                            bookmarkId,
                            eventId
                        }
                    );
                })
                .catch(() => {
                    this.setState({ submitting: false });
                });
        });
    }

    onTranscrippet(event, cb) {
        event.preventDefault();
        event.stopPropagation();
        const { createTranscrippet, eventId, reporter, history, tabs, pathname } = this.props;
        this.setState({ creatingTranscrippet: true }, () => {
            const transcript = this.getHighlight();
            const { targetId, targetEndId } = this.getTargets();
            createTranscrippet({
                transcript: transcript.text,
                transcriptItemId: targetId,
                transcriptItemOffset: transcript.startOffset,
                transcriptEndItemId: targetEndId,
                transcriptEndItemOffset: targetEndId ? transcript.endOffset - 1 : undefined,
                eventId
            })
                .then(({ data }) => {
                    const transcrippetGuid = get(data, 'createTranscrippet.transcrippet.transcrippetGuid');
                    history.push(
                        generateModalId({ pathname, id: transcrippetGuid, type: 'transcrippet', tabId: tabs[0] })
                    );
                    // Track Transcrippet
                    reporter.track(reporter.actions.submit, reporter.objects.transcrippetCreate, {
                        component: 'EventActionMenu',
                        eventId
                    });
                    this.setState({ creatingTranscrippet: false }, () => {
                        if (cb) cb();
                    });
                })
                .catch(() => {
                    this.setState({ creatingTranscrippet: false });
                });
        });
    }

    onFlag() {
        const { flag, eventItemId, eventId } = this.props;
        const { flagged } = this.state;

        if (!flagged && flag && eventItemId && eventId) {
            this.setState(
                {
                    flagged: true
                },
                () => {
                    flag({
                        issueText: this.getHighlight().text || '',
                        eventId,
                        eventItemId,
                        issueCategory: 'transcription'
                    });
                }
            );
        }
    }

    openBookmarkForm(event) {
        event.preventDefault();
        event.stopPropagation();
        const {
            addHighlight,
            bookmarkId,
            eventId,
            highlightsFilterKey,
            highlightsSortKey,
            history,
            onHighlight,
            pathname,
            tabs
        } = this.props;
        const highlight = this.getHighlight();
        const { targetId, targetEndId } = this.getTargets();
        addHighlight({
            highlight: highlight.text,
            highlightsSortKey,
            highlightsFilterKey,
            targetType: 'transcript',
            targetEndId,
            targetEndOffset: highlight.endOffset - 1, // Server wants exact bounds
            targetId,
            targetOffset: highlight.startOffset,
            eventId
        });
        history.push(generateModalId({ pathname, id: bookmarkId || 'new', type: 'bookmark', tabId: tabs[0] }));
        if (onHighlight) onHighlight();
    }

    openEditor() {
        const { eventItemId } = this.props;

        if (eventItemId) {
            const path = generatePath(externalRoutes.transcriptEditorItem, { itemId: eventItemId });
            const url = `${config.DASHBOARD_ENDPOINT}${path.slice(1)}`;
            window.open(url, '_blank').focus();
        }
    }

    render() {
        const { copiedLink, copiedText, creatingTranscrippet, submitting, flagged } = this.state;
        const {
            doNotRender,
            bookmarkId,
            eventType,
            flagEnabled,
            hideTooltip,
            isEventOwner,
            isPublished,
            onEditTranscript,
            onDeleteTranscript,
            onHide,
            onShow,
            openByDefault,
            styles
        } = this.props;

        if (doNotRender) {
            return null;
        }

        return (
            <EventActionMenuUI
                bookmarkId={bookmarkId}
                copiedLink={copiedLink}
                copiedText={copiedText}
                copyLinkToClipboard={this.copyLinkToClipboard}
                copyTextToClipboard={this.copyTextToClipboard}
                creatingTranscrippet={creatingTranscrippet}
                eventType={eventType}
                flagEnabled={flagEnabled}
                flagged={flagged}
                hideTooltip={hideTooltip}
                isEventOwner={isEventOwner}
                isPublished={isPublished}
                onBookmark={this.onBookmark}
                onTranscrippet={this.onTranscrippet}
                onEditTranscript={onEditTranscript}
                onDeleteTranscript={onDeleteTranscript}
                onFlag={this.onFlag}
                onHide={onHide}
                onShow={onShow}
                openBookmarkForm={this.openBookmarkForm}
                openByDefault={openByDefault}
                openEditor={this.openEditor}
                styles={styles}
                submitting={submitting}
            />
        );
    }
}

const mapDispatchToProps = {
    addHighlight: highlightAdd
};

const mapStateToProps = ({ Highlight }) => ({
    selectionStartId: get(Highlight, 'selectionStartId')
});

export const EventActionMenuContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withUrlContext(['history', 'pathname', 'createTabPath', 'tabs']),
    withCreateTranscrippet(),
    withCreateBookmark(),
    withDeleteBookmark(),
    withEventDetails({
        fetchPolicy: 'cache-only'
    }),
    withPropsOnChange(['audioCall'], ({ audioCall }) => ({
        transcriptEvents: get(audioCall, 'events', [])
    })),
    withData(),
    withReporting()
)(EventActionMenu);
