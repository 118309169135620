export const styleSheet = theme => ({
    container: {},
    toolbar: {
        position: 'relative',
        backgroundColor: theme.colors.gray01,
        display: 'flex',
        alignItems: 'center',
        minHeight: 40,
        height: 40,
        zIndex: 2,
        borderRadius: '4px 4px 0 0',
        overflow: 'hidden',
        padding: 2,
        paddingRight: 4
    },
    title: {
        flex: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: theme.fonts.fontSizes.size03,
        fontWeight: theme.fonts.fontWeights.normal,
        marginRight: theme.margins.margin00,
        a: {
            color: 'inherit',
            textDecoration: 'none',
            ':hover': {
                cursor: 'pointer',
                textDecoration: 'underline'
            }
        }
    },
    remove: {
        cursor: 'pointer',
        height: 44,
        width: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        svg: {
            height: 12,
            width: 12
        },
        ':hover': {
            'svg g': {
                fill: theme.colors.red01
            }
        }
    },
    smartStatuses: {
        marginTop: 16
    },
    smartStatus: {
        display: 'flex',
        alignItems: 'start',
        lineHeight: '20px',
        marginBottom: theme.margins.margin00
    },
    smartContent: {
        color: theme.colors.gray06,
        margin: '0 17px',
        textWrap: 'balance'
    },
    smartTime: {
        color: theme.colors.gray06,
        textAlign: 'right',
        flexShrink: 0,
        width: 64
    },
    smartLink: {
        marginLeft: 64,
        color: theme.colors.blue08,
        fontWeight: theme.fonts.fontWeights.medium,
        backgroundColor: theme.colors.blue07,
        padding: '4px 6px',
        borderRadius: 6,
        fontSize: theme.fonts.fontSizes.size02,
        textDecoration: 'none'
    },
    playButton: {
        display: 'flex',
        backgroundColor: theme.colors.gray07,
        height: 28,
        width: 28,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 14,
        transition: 'all 0.2s ease-in-out',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: theme.colors.blue11,
            'svg g': {
                fill: theme.colors.white01
            }
        },
        '> div': {
            height: 16,
            width: 16
        },
        svg: {
            marginLeft: 2,
            height: 12,
            width: 14
        }
    },
    playButtonPlaying: {
        display: 'flex',
        backgroundColor: theme.colors.blue11,
        height: 28,
        width: 28,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 14,
        transition: 'all 0.2s ease-in-out',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: theme.colors.red01,
            'svg g': {
                fill: theme.colors.white01
            }
        },
        '> div': {
            height: 16,
            width: 16
        },
        svg: {
            height: 12,
            width: 14,
            g: {
                fill: theme.colors.white01
            }
        }
    },
    transcriptContainer: {
        position: 'relative',
        backgroundColor: theme.colors.white01,
        paddingTop: 5
    },
    transcriptContainerSearched: {
        position: 'relative',
        paddingTop: 5
    },
    upcoming: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: `30px 15px`,
        backgroundColor: theme.colors.white01
    },
    eventTitle: {
        lineHeight: '1.3em',
        textAlign: 'center',
        fontWeight: theme.fonts.fontWeights.regular
    },
    eventTime: {
        marginTop: theme.margins.margin00,
        color: theme.colors.gray06,
        textAlign: 'center'
    },
    countdown: {
        marginTop: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    header: {
        zIndex: 1,
        boxShadow: '0 1px 3px 0 rgba(38, 39, 53, 0.16)'
    },
    isPublished: {
        backgroundColor: theme.colors.white01,
        padding: theme.margins.margin01,
        textAlign: 'center',
        fontSize: theme.fonts.fontSizes.size02
    },
    name: {
        backgroundColor: theme.colors.white01
    },
    equityContainer: {
        padding: theme.margins.margin00
    },
    equityPriceRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline'
    },
    equityInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minWidth: 0,
        h2: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            color: theme.colors.black01,
            textOverflow: 'ellipsis',
            lineHeight: '20px',
            marginBottom: -2,
            marginRight: 2
        },
        p: {
            color: theme.colors.gray06,
            letterSpacing: 1,
            paddingTop: 4
        }
    },
    priceInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: `5px 0`
    },
    price: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        whiteSpace: 'nowrap',
        p: {
            lineHeight: '16px'
        }
    },
    priceChange: {
        whiteSpace: 'pre-wrap',
        display: 'flex',
        alignItems: 'center',
        svg: {
            marginLeft: 2
        }
    },
    textGray: {
        color: theme.colors.gray04
    },
    textGreen: {
        color: theme.colors.green02
    },
    textRed: {
        color: theme.colors.orange02
    },
    upsellGate: {
        position: 'absolute',
        top: 40,
        bottom: 4,
        left: 0,
        right: 4,
        backdropFilter: 'blur(2px)',
        zIndex: 1,
        borderRadius: '0 0 6px 6px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.margins.margin01
    },
    upsellContent: {
        cursor: 'pointer',
        padding: '14px 16px',
        backgroundColor: theme.colors.yellow09,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 6,
        boxShadow: '0 6px 8px 0 rgba(0, 0, 0, 0.06)',
        p: {
            lineHeight: '1.3em'
        },
        ':hover': {
            backgroundColor: theme.colors.yellow07
        },
        ':active': {
            backgroundColor: theme.colors.yellow08
        }
    },
    upsellTitle: {
        marginBottom: theme.margins.margin00,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        svg: {
            marginBottom: 2,
            marginRight: 8
        }
    },
    loader: {
        padding: theme.margins.margin00,
        backgroundColor: theme.colors.white01,
        height: 30
    },
    detailsButton: {
        borderRadius: 6,
        boxShadow: 'unset',
        backgroundColor: theme.colors.blue08,
        ':hover': {
            backgroundColor: theme.colors.blue10
        },
        ':active': {
            backgroundColor: theme.colors.blue11
        },
        height: 30,
        padding: `0 ${theme.margins.margin00}`,
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        cursor: 'pointer',
        p: {
            color: theme.colors.white01,
            marginLeft: 4
        }
    },
    noTranscriptText: {
        color: theme.colors.gray04,
        textAlign: 'center',
        maxWidth: 350,
        lineHeight: '20px'
    },
    missedText: {
        marginTop: 30
    }
});
