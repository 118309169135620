import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { generateTabURL, getNativePrice, hasPermission, toTimeString } from 'utils';
import { PERMISSIONS } from 'consts';
import { withStyleSheet } from 'hoc/styles';
import { ActionButton } from 'components/ActionButton';
import { Div } from 'components/Div';
import { EventPriceChart } from 'components/EventPriceChart';
import { EventCountdown } from 'components/EventCountdown';
import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { InternalLink } from 'components/InternalLink';
import { LiveTranscriptRow } from 'components/LiveTranscriptRow';
import { LoaderDots } from 'components/LoaderDots';
import { SortHandle } from 'components/SortHandle';
import { Spinner } from 'components/Spinner';
import { Stream } from 'components/Stream';
import { Text } from 'components/Text';
import { TextHint } from 'components/TextHint';
import { WithPermission } from 'components/WithPermission';
import { styleSheet } from './stylesheet';

const COMPONENT_MAP = {
    transcript: LiveTranscriptRow
};

const eventText = '"Call" measures change since the start of the call.';
const dayText = '"Since close" measures change since most recent closing price.';

class TranscriptStream extends PureComponent {
    static propTypes = {
        bottomUp: PropTypes.bool,
        chartRef: PropTypes.objectOf(PropTypes.any),
        dashSearchTerm: PropTypes.string,
        dashboardId: PropTypes.string.isRequired,
        equityId: PropTypes.string,
        eventDate: PropTypes.string,
        eventHref: PropTypes.string,
        eventType: PropTypes.string,
        eventId: PropTypes.string,
        eventTitle: PropTypes.string,
        expectPublishedTranscript: PropTypes.bool,
        hasDetails: PropTypes.bool,
        hasPartial: PropTypes.bool,
        hasPublishedTranscript: PropTypes.bool,
        loadMoreMatches: PropTypes.func,
        loading: PropTypes.bool,
        loadingAudio: PropTypes.bool,
        matches: PropTypes.arrayOf(PropTypes.object),
        mediaPlayer: PropTypes.objectOf(PropTypes.any),
        openSubmitDetails: PropTypes.func.isRequired,
        openUpgradeModal: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        pathname: PropTypes.string.isRequired,
        smartStatuses: PropTypes.arrayOf(PropTypes.any).isRequired,
        streamDisplayMode: PropTypes.string.isRequired,
        streamId: PropTypes.string,
        streamProps: PropTypes.objectOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any).isRequired,
        subtitle: PropTypes.string,
        theme: PropTypes.objectOf(PropTypes.any).isRequired,
        togglePlayEvent: PropTypes.func.isRequired,
        transcriptionStatus: PropTypes.string,
        user: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        chartRef: null,
        dashSearchTerm: null,
        eventId: null,
        eventDate: null,
        eventTitle: null,
        eventType: null,
        eventHref: '',
        equityId: null,
        expectPublishedTranscript: false,
        hasDetails: false,
        hasPublishedTranscript: false,
        loading: false,
        loadMoreMatches: null,
        loadingAudio: false,
        matches: [],
        mediaPlayer: null,
        hasPartial: false,
        passedStyles: {},
        bottomUp: false,
        streamId: null,
        subtitle: undefined,
        transcriptionStatus: undefined,
        user: undefined
    };

    constructor(props) {
        super(props);
        this.renderChartHeader = this.renderChartHeader.bind(this);
    }

    renderToolbar({ dragHandleProps, onRemove, title }) {
        const { eventHref, loadingAudio, mediaPlayer, styles, theme, togglePlayEvent } = this.props;
        const playing = mediaPlayer && !['stopped', 'paused'].includes(mediaPlayer.status);
        const playStyles = playing ? styles.playButtonPlaying : styles.playButton;
        const loaderColor = playing || loadingAudio ? 'white' : 'darkGray';
        return (
            <Div styles={styles.toolbar} {...dragHandleProps}>
                <SortHandle color={theme.colors.gray04} />
                <Heading styles={styles.title} size={2}>
                    <Link to={eventHref}>{title}</Link>
                </Heading>
                <WithPermission permissions={[PERMISSIONS.unlockedLiveEvents]}>
                    {mediaPlayer && (
                        <Div styles={playStyles} data-tname="aieracast-play-live" onClick={togglePlayEvent}>
                            {mediaPlayer.loading || loadingAudio ? (
                                <Spinner styles={styles.spinner} color={loaderColor} />
                            ) : (
                                <Icon type={playing ? 'pause' : 'play'} color={theme.colors.blue08} />
                            )}
                        </Div>
                    )}
                </WithPermission>
                <Div styles={styles.remove} onClick={() => onRemove(false)}>
                    <Icon type="xMark02" color={theme.colors.gray06} />
                </Div>
            </Div>
        );
    }

    renderChartHeader({
        currency,
        sinceCloseChange,
        sinceCloseChangePercent,
        equityHref,
        callChange,
        callChangePercent,
        exchange,
        latestPrice,
        name,
        ticker
    }) {
        const { styles, theme, equityId } = this.props;
        const upArrow = <Icon type="triangleUp" color={theme.colors.green02} />;
        const downArrow = <Icon type="triangleDown" color={theme.colors.orange02} />;

        if (!equityId) return null;

        return (
            <Div styles={styles.equityContainer}>
                <Div styles={styles.equityPriceRow}>
                    <Div styles={styles.equityInfo} className="equityInfo">
                        {equityHref ? (
                            <InternalLink styles={styles.name} to={equityHref}>
                                <Heading size={2}>{name}</Heading>
                            </InternalLink>
                        ) : (
                            <Heading size={2}>{name}</Heading>
                        )}
                        {ticker && (
                            <Text size={0} uppercase>
                                {`${ticker}:${exchange}`}
                            </Text>
                        )}
                    </Div>
                    <Div styles={styles.price}>
                        <Text size={4}>{getNativePrice({ price: latestPrice, currency })}</Text>
                    </Div>
                </Div>
                {latestPrice && (
                    <Div styles={styles.priceInfo}>
                        <Div styles={styles.priceChange}>
                            <TextHint text={eventText} position="top" title="Change during call">
                                <Text size={1}>Call </Text>
                            </TextHint>
                            <Div styles={styles.spacer} />
                            <Text
                                size={1}
                                styles={
                                    callChange > 0
                                        ? styles.textGreen
                                        : callChange < 0
                                        ? styles.textRed
                                        : styles.textGray
                                }
                            >
                                {callChange >= 0 && '+'}
                                {typeof callChange === 'number' ? callChange.toFixed(2) : '-.--'} (
                                {typeof callChangePercent === 'number' ? callChangePercent.toFixed(2) : '-.--'}%)
                            </Text>
                            {callChange > 0 && upArrow}
                            {callChange < 0 && downArrow}
                        </Div>
                        <Div styles={styles.priceChange}>
                            <TextHint text={dayText} position="top" title="Change since close">
                                <Text size={1}>Since close </Text>
                            </TextHint>
                            <Div styles={styles.spacer} />
                            <Text
                                size={1}
                                styles={
                                    sinceCloseChange > 0
                                        ? styles.textGreen
                                        : sinceCloseChange < 0
                                        ? styles.textRed
                                        : styles.textGray
                                }
                            >
                                {sinceCloseChange >= 0 && '+'}
                                {typeof sinceCloseChange === 'number' ? sinceCloseChange.toFixed(2) : '-.--'} (
                                {typeof sinceCloseChangePercent === 'number'
                                    ? sinceCloseChangePercent.toFixed(2)
                                    : '-.--'}
                                %)
                            </Text>
                            {sinceCloseChange > 0 && upArrow}
                            {sinceCloseChange < 0 && downArrow}
                        </Div>
                    </Div>
                )}
            </Div>
        );
    }

    renderHeader({ onRemove }) {
        const { chartRef, eventHref, eventId, eventType, equityId, hasPublishedTranscript, styles } = this.props;
        return (
            <Div styles={styles.header}>
                <EventPriceChart
                    eventType={eventType}
                    styles={styles.equityChart}
                    chartRef={chartRef}
                    eventId={eventId}
                    equityId={equityId}
                    renderChartHeader={this.renderChartHeader}
                />
                {hasPublishedTranscript && (
                    <InternalLink styles={styles.isPublished} to={eventHref} onClick={() => onRemove(false)}>
                        Click here to close and view the published transcript
                    </InternalLink>
                )}
            </Div>
        );
    }

    renderNoTranscripts() {
        const {
            dashSearchTerm,
            eventDate,
            eventTitle,
            eventType,
            expectPublishedTranscript,
            hasDetails,
            openSubmitDetails,
            styles,
            smartStatuses,
            pathname,
            transcriptionStatus
        } = this.props;
        const diffSeconds = eventDate ? new XDate().diffSeconds(new XDate(eventDate)) : -Infinity;

        let content;
        if (diffSeconds > 0 && hasDetails) {
            content = (
                <Text size={3} styles={styles.noTranscriptText}>
                    The transcript will appear here after the event begins.
                    {expectPublishedTranscript && (
                        <Fragment>
                            <br />
                            <br />
                            We expect to get the published transcript for this event sometime after it has ended.
                        </Fragment>
                    )}
                </Text>
            );
        } else if (diffSeconds > 0 && eventType !== 'custom' && !hasDetails) {
            content = (
                <Fragment>
                    <Text size={3} styles={styles.noTranscriptText}>
                        We will transcribe this event if we receive connection details before the event&apos;s start
                        time.
                        <br />
                        <br />
                    </Text>
                    <ActionButton onClick={openSubmitDetails} styles={styles.detailsButton}>
                        <Text size={1}>Have the details?</Text>
                    </ActionButton>
                </Fragment>
            );
        } else if (diffSeconds < 0 && eventType !== 'custom' && !!dashSearchTerm) {
            content = (
                <Fragment>
                    <Text size={3} styles={[styles.noTranscriptText, styles.missedText]}>
                        No matches for &ldquo;{dashSearchTerm}&rdquo;.
                    </Text>
                </Fragment>
            );
        } else if (diffSeconds < 0 && eventType !== 'custom' && transcriptionStatus === 'missed') {
            content = (
                <Fragment>
                    <Text size={3} styles={[styles.noTranscriptText, styles.missedText]}>
                        Sorry, we were unable to transcribe this event
                        {expectPublishedTranscript ? ', but we expect to get the published transcript shortly.' : '.'}
                    </Text>
                </Fragment>
            );
        } else if (diffSeconds < 0 && eventType !== 'custom') {
            content = (
                <Fragment>
                    <Text size={3} styles={[styles.noTranscriptText, styles.missedText]}>
                        We are attemping to connect, <br />
                        this may take up to 3 minutes,
                        <br /> please wait.
                    </Text>
                </Fragment>
            );
        }

        return smartStatuses.length > 0 ? (
            <Div styles={styles.smartStatuses}>
                {smartStatuses.map(({ id, content: statusContent, created, relatedEventId }) => (
                    <Fragment key={id}>
                        <Text styles={styles.smartStatus}>
                            <Text span size={1} uppercase styles={styles.smartTime}>
                                {toTimeString(created)}
                            </Text>
                            <Text span size={2} styles={styles.smartContent}>
                                {statusContent}
                            </Text>
                        </Text>
                        {relatedEventId && (
                            <Link style={styles.smartLink} to={generateTabURL({ eventId: relatedEventId, pathname })}>
                                Go To Related Event
                            </Link>
                        )}
                    </Fragment>
                ))}
            </Div>
        ) : (
            <Div styles={styles.upcoming}>
                <Heading size={3} styles={styles.eventTitle}>
                    {eventTitle}
                </Heading>
                {eventDate && diffSeconds > 0 ? this.renderCountdown() : null}
                {content}
            </Div>
        );
    }

    renderCountdown() {
        const { eventDate, styles } = this.props;
        return (
            eventDate && (
                <Fragment>
                    <Text styles={styles.eventTime}>at {new XDate(eventDate).toString('h:mmTT')}</Text>
                    <Div styles={styles.countdown}>
                        <EventCountdown showDays callDate={eventDate} />
                    </Div>
                </Fragment>
            )
        );
    }

    render() {
        const {
            bottomUp,
            dashboardId,
            dashSearchTerm,
            eventId,
            hasPublishedTranscript,
            loadMoreMatches,
            loading,
            matches,
            openUpgradeModal,
            hasPartial,
            passedStyles,
            streamDisplayMode,
            streamId,
            streamProps,
            styles,
            subtitle,
            theme,
            user
        } = this.props;
        const hasContent = matches && matches.length;
        const transcriptContainerStyle = dashSearchTerm
            ? styles.transcriptContainerSearched
            : styles.transcriptContainer;

        let items = [];
        if (
            !dashSearchTerm &&
            !hasPublishedTranscript &&
            hasPartial &&
            hasContent &&
            !hasPermission(user, PERMISSIONS.transcriptsOnlyEdited) &&
            !hasPermission(user, PERMISSIONS.transcriptsOnlyImproved)
        ) {
            items.push(<LiveTranscriptRow isPartial eventId={eventId} key={`live-transcript-row-${eventId}`} />);
        }
        items = items.concat(
            matches.map(m => {
                const CardComponent = COMPONENT_MAP[m.type];
                return (
                    <CardComponent
                        displayMode={streamDisplayMode}
                        streamId={streamId}
                        key={`transcript-card-${m.id}`}
                        dashSearchTerm={dashSearchTerm}
                        {...m}
                    />
                );
            })
        );
        if (!bottomUp) {
            items.reverse();
        }

        const loader = (
            <Div styles={styles.loader}>
                <LoaderDots gray />
            </Div>
        );

        return (
            <Stream
                {...streamProps}
                dashboardId={dashboardId}
                loadMoreMatches={loadMoreMatches}
                loading={loading}
                loaderElement={loader}
                offset={matches.length}
                renderHeader={props => this.renderHeader(props)}
                renderToolbar={props => this.renderToolbar(props)}
                streamId={streamId}
                styles={{ ...styles.container, ...passedStyles }}
                subtitle={subtitle}
                invert={!bottomUp}
            >
                <Fragment>
                    {loading || hasContent ? (
                        <Fragment>
                            <Div styles={hasContent ? transcriptContainerStyle : null}>{items}</Div>
                        </Fragment>
                    ) : (
                        this.renderNoTranscripts()
                    )}
                    <WithPermission permissions={[PERMISSIONS.unlockedLiveEvents]}>
                        {({ restricted }) => (
                            <Fragment>
                                {restricted && (
                                    <Div styles={styles.upsellGate} onClick={openUpgradeModal}>
                                        <Div styles={styles.upsellContent}>
                                            <Div styles={styles.upsellTitle}>
                                                <Text size={5} weight="medium">
                                                    Unlock Live Events
                                                </Text>
                                                <Icon type="lock02" color={theme.colors.black01} />
                                            </Div>
                                            <Text size={3}>
                                                Stream unlimited events in parallel, with live transcription, and 1
                                                click to listen.
                                            </Text>
                                        </Div>
                                    </Div>
                                )}
                            </Fragment>
                        )}
                    </WithPermission>
                </Fragment>
            </Stream>
        );
    }
}

export const TranscriptStreamUI = compose(withStyleSheet(styleSheet))(TranscriptStream);
