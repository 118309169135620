import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { compose, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import ResizeObserver from 'resize-observer-polyfill';
import { statusBannerFire } from 'actions/statusBanner';
import { PREFERENCES } from 'consts';
import { theme } from 'styles';
import { withReporting } from 'provider/reporting';
import { withUrlContext } from 'hoc/url';
import { withEventDetails, withEventMediaPlayer } from 'graphql/audioCalls';
import { withUserPreferences } from 'graphql/user';
import { get, generateModalId, hasPreference } from 'utils';
import { EventTranscriptsUI } from './ui';
import { withData } from './data';

const MARKUP_TYPES = {
    annotations: PREFERENCES.showTranscriptAnnotations,
    highlights: PREFERENCES.showTranscriptHighlights,
    sentiment: PREFERENCES.showTranscriptSentiment,
    tonalSentiment: PREFERENCES.showTranscriptTonalSentiment
};

function mapPreferencesToMarkupTypes(preferences) {
    const types = [];
    if (hasPreference(preferences, { ...PREFERENCES.showTranscriptAnnotations, value: true }, true)) {
        types.push('annotations');
    }
    if (hasPreference(preferences, { ...PREFERENCES.showTranscriptHighlights, value: true }, true)) {
        types.push('highlights');
    }
    if (hasPreference(preferences, { ...PREFERENCES.showTranscriptSentiment, value: true }, true)) {
        types.push('sentiment');
    }
    if (hasPreference(preferences, { ...PREFERENCES.showTranscriptTonalSentiment, value: true }, true)) {
        types.push('tonalSentiment');
    }
    return types;
}

function mapPreferencesToSpeakers(preferences) {
    return hasPreference(preferences, { ...PREFERENCES.showEstimatedSpeakers, value: true }, true);
}

function mapPreferencesToHumanEdited(preferences) {
    return hasPreference(preferences, { ...PREFERENCES.showHumanEditedOnly, value: true }, false);
}

function mapPreferencesToAudioScroll(preferences) {
    return hasPreference(preferences, { ...PREFERENCES.lockAudioScroll, value: true }, false);
}

function mapPreferencesToTimestamps(preferences) {
    return hasPreference(preferences, { ...PREFERENCES.showTranscriptTimestamps, value: true }, true);
}

export class EventTranscripts extends PureComponent {
    static displayName = 'EventTranscriptsContainer';

    static propTypes = {
        attachments: PropTypes.arrayOf(PropTypes.any),
        audioCall: PropTypes.objectOf(PropTypes.any),
        audioCallId: PropTypes.string.isRequired,
        disableQA: PropTypes.bool.isRequired,
        displayType: PropTypes.string.isRequired,
        editedEvents: PropTypes.arrayOf(PropTypes.any).isRequired,
        failureCode: PropTypes.string,
        focusMode: PropTypes.bool.isRequired,
        handleRef: PropTypes.func.isRequired,
        hasPrivateRecordingAudio: PropTypes.bool.isRequired,
        hasSummary: PropTypes.bool.isRequired,
        highlightsFilterKey: PropTypes.string.isRequired,
        highlightsSortKey: PropTypes.string.isRequired,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        isEditing: PropTypes.bool.isRequired,
        isPublic: PropTypes.bool,
        isPrivateRecording: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        mediaPlayer: PropTypes.objectOf(PropTypes.any),
        onKeywordSearch: PropTypes.func.isRequired,
        onNodeSelect: PropTypes.func.isRequired,
        onSearchClear: PropTypes.func.isRequired,
        onTimeSelect: PropTypes.func.isRequired,
        pathname: PropTypes.string.isRequired,
        preferences: PropTypes.objectOf(PropTypes.any),
        processingAudio: PropTypes.bool.isRequired,
        reconnectTranscription: PropTypes.func.isRequired,
        reporter: PropTypes.shape({
            actions: PropTypes.object,
            objects: PropTypes.object,
            track: PropTypes.func
        }).isRequired,
        reportIssue: PropTypes.func.isRequired,
        resultsIndex: PropTypes.number.isRequired,
        retryConnection: PropTypes.func.isRequired,
        reverseTranscripts: PropTypes.bool,
        saveUXPreference: PropTypes.func.isRequired,
        scrollToBottom: PropTypes.func.isRequired,
        scrollToTop: PropTypes.func.isRequired,
        searchResults: PropTypes.arrayOf(PropTypes.string),
        searchTerm: PropTypes.string.isRequired,
        selectedEvent: PropTypes.objectOf(PropTypes.any),
        selectedNode: PropTypes.string,
        setResultsIndex: PropTypes.func.isRequired,
        setStatusBanner: PropTypes.func.isRequired,
        shareId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        streamId: PropTypes.string,
        streamMatches: PropTypes.arrayOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.any),
        summaryAudioUrl: PropTypes.string,
        summaryText: PropTypes.arrayOf(PropTypes.any),
        summaryTitle: PropTypes.string,
        tabId: PropTypes.string,
        toggleFocus: PropTypes.func.isRequired,
        toggleReverse: PropTypes.func.isRequired,
        transcriptionStatus: PropTypes.string,
        uploadPercentComplete: PropTypes.number,
        uploadStatus: PropTypes.string,
        user: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        attachments: [],
        audioCall: null,
        failureCode: undefined,
        isPublic: false,
        mediaPlayer: null,
        preferences: {},
        reverseTranscripts: true,
        searchResults: null,
        selectedEvent: null,
        selectedNode: null,
        shareId: null,
        streamId: null,
        styles: undefined,
        summaryAudioUrl: undefined,
        summaryText: [],
        summaryTitle: '',
        tabId: undefined,
        transcriptionStatus: undefined,
        uploadPercentComplete: undefined,
        uploadStatus: undefined,
        user: null
    };

    constructor(props) {
        super(props);

        this.openSubmitDetails = this.openSubmitDetails.bind(this);
        this.openUpgradeModal = this.openUpgradeModal.bind(this);
        this.hideTranscriptVersionNotice = this.hideTranscriptVersionNotice.bind(this);
        this.saveSettings = this.saveSettings.bind(this);
        this.toggleAudioScroll = this.toggleAudioScroll.bind(this);
        this.toggleChart = this.toggleChart.bind(this);
        this.toggleEstimatedSpeakers = this.toggleEstimatedSpeakers.bind(this);
        this.toggleTimestamps = this.toggleTimestamps.bind(this);
        this.toggleHumanEdited = this.toggleHumanEdited.bind(this);
        this.toggleMarkup = this.toggleMarkup.bind(this);
        this.toggleSummary = this.toggleSummary.bind(this);
        this.toggleTranslation = this.toggleTranslation.bind(this);
        this.startAudio = this.startAudio.bind(this);
        this.stopAudio = this.stopAudio.bind(this);

        this.retryConnection = this.retryConnection.bind(this);
        this.retryTranscription = this.retryTranscription.bind(this);
        this.reportIssue = this.reportIssue.bind(this);
        this.setIssueText = this.setIssueText.bind(this);
        this.setIssue = this.setIssue.bind(this);
        this.clearIssues = this.clearIssues.bind(this);

        this.mapPreferencesToMarkupTypes = memoize(mapPreferencesToMarkupTypes);
        this.mapPreferencesToSpeakers = memoize(mapPreferencesToSpeakers);
        this.mapPreferencesToHumanEdited = memoize(mapPreferencesToHumanEdited);
        this.mapPreferencesToAudioScroll = memoize(mapPreferencesToAudioScroll);
        this.mapPreferencesToTimestamps = memoize(mapPreferencesToTimestamps);

        this.observer = new ResizeObserver(this.onStickySizeChange.bind(this));
        this.stickyRef = createRef();

        this.state = {
            issues: [],
            issueText: '',
            issueReported: false,
            loadingAudio: false,
            lockAudioScroll: this.mapPreferencesToAudioScroll(props.preferences),
            markupTypes: this.mapPreferencesToMarkupTypes(props.preferences),
            showEstimatedSpeakers: this.mapPreferencesToSpeakers(props.preferences),
            showHumanEditedOnly: this.mapPreferencesToHumanEdited(props.preferences),
            showTimestamps: this.mapPreferencesToTimestamps(props.preferences),
            showTranscriptVersionNotice: true,
            showTranslation: false,
            stickyHeight: 51, // Height of the search box
            showChart: window.matchMedia
                ? window.matchMedia(`(min-width: ${theme.breakpoints.internal.mobileEdge})`).matches
                : true,
            showSummary: false
        };
    }

    componentDidMount() {
        this.observer.observe(this.stickyRef.current);
    }

    componentDidUpdate({ preferences: prevPreferences }) {
        const { preferences } = this.props;
        if (!prevPreferences && preferences) {
            this.setState({
                lockAudioScroll: this.mapPreferencesToAudioScroll(preferences),
                markupTypes: this.mapPreferencesToMarkupTypes(preferences),
                showEstimatedSpeakers: this.mapPreferencesToSpeakers(preferences),
                showTimestamps: this.mapPreferencesToTimestamps(preferences),
                showHumanEditedOnly: this.mapPreferencesToHumanEdited(preferences)
            });
        }
    }

    componentWillUnmount() {
        this.observer.disconnect();
        this.observer = null;
    }

    retryConnection() {
        const { retryConnection } = this.props;
        retryConnection(() => this.setState({ issueReported: true, issues: [] }));
    }

    retryTranscription() {
        const { reconnectTranscription } = this.props;
        reconnectTranscription(() => this.setState({ issueReported: true, issues: [] }));
    }

    reportIssue() {
        const { reportIssue, audioCall } = this.props;
        const { issues, issueText } = this.state;
        const issuesString = issueText ? `[${issues.join(', ')}]: ${issueText}` : `[${issues.join(', ')}]`;

        const hasPublishedTranscript = get(audioCall, 'hasPublishedTranscript');
        if (hasPublishedTranscript) {
            reportIssue(`Audio Sync Issue: ${issuesString}`, () => this.setState({ issueReported: true, issues: [] }));
        } else {
            reportIssue(issuesString, () => this.setState({ issueReported: true }));
        }
    }

    setIssueText({ value }) {
        this.setState({ issueText: value });
    }

    clearIssues() {
        this.setState({ issues: [] });
    }

    setIssue({ value }) {
        this.setState(({ issues }) => {
            const uniqueIssues = new Set(issues);
            if (uniqueIssues.has(value)) {
                uniqueIssues.delete(value);
            } else {
                uniqueIssues.add(value);
            }
            return {
                issues: [...uniqueIssues]
            };
        });
    }

    hideTranscriptVersionNotice(event, permanently = false) {
        const { saveUXPreference } = this.props;
        this.setState({ showTranscriptVersionNotice: false });
        if (permanently) {
            saveUXPreference({
                name: PREFERENCES.hideTranscriptVersionNotice.name,
                value: true,
                hideSuccessBanner: true
            });
        }
    }

    onStickySizeChange(entries) {
        this.setState({
            stickyHeight: get(entries, '[0].contentRect.height')
        });
    }

    openSubmitDetails() {
        const { audioCallId, history } = this.props;
        history.push(generateModalId({ location: window.location, id: audioCallId, type: 'submitEventDetails' }));
    }

    openUpgradeModal() {
        const { pathname, history, tabId } = this.props;
        history.push(generateModalId({ pathname, id: 'liveEvents', type: 'upgrade', tabId }));
    }

    saveSettings() {
        const { markupTypes, showEstimatedSpeakers, showTimestamps, showHumanEditedOnly, lockAudioScroll } = this.state;
        const { preferences, saveUXPreference, setStatusBanner } = this.props;
        const mutations = [];
        Object.keys(MARKUP_TYPES).forEach(key => {
            const pref = MARKUP_TYPES[key];
            const hasPref = hasPreference(preferences, { ...pref, value: true }, false);
            if (markupTypes.includes(key) && !hasPref) {
                mutations.push(saveUXPreference({ name: pref.name, value: true, hideSuccessBanner: true }));
            }
            if (!markupTypes.includes(key) && hasPref) {
                mutations.push(saveUXPreference({ name: pref.name, value: false, hideSuccessBanner: true }));
            }
        });
        if (!hasPreference(preferences, { ...PREFERENCES.showEstimatedSpeakers, value: showEstimatedSpeakers }, true)) {
            mutations.push(
                saveUXPreference({
                    name: PREFERENCES.showEstimatedSpeakers.name,
                    value: showEstimatedSpeakers,
                    hideSuccessBanner: true
                })
            );
        }
        if (!hasPreference(preferences, { ...PREFERENCES.showTranscriptTimestamps, value: showTimestamps }, true)) {
            mutations.push(
                saveUXPreference({
                    name: PREFERENCES.showTranscriptTimestamps.name,
                    value: showTimestamps,
                    hideSuccessBanner: true
                })
            );
        }
        if (!hasPreference(preferences, { ...PREFERENCES.showHumanEditedOnly, value: showHumanEditedOnly }, false)) {
            mutations.push(
                saveUXPreference({
                    name: PREFERENCES.showHumanEditedOnly.name,
                    value: showHumanEditedOnly,
                    hideSuccessBanner: true
                })
            );
        }
        if (!hasPreference(preferences, { ...PREFERENCES.lockAudioScroll, value: lockAudioScroll }, false)) {
            mutations.push(
                saveUXPreference({
                    name: PREFERENCES.lockAudioScroll.name,
                    value: lockAudioScroll,
                    hideSuccessBanner: true
                })
            );
        }
        if (mutations.length) {
            Promise.all(mutations).catch(error =>
                setStatusBanner(`Error updating preferences: ${error}`, 'error', 'circleX')
            );
        }
    }

    toggleAudioScroll() {
        this.setState(({ lockAudioScroll }) => ({ lockAudioScroll: !lockAudioScroll }));
    }

    toggleChart() {
        this.setState(({ showChart }) => ({ showChart: !showChart }));
    }

    toggleMarkup({ value }) {
        this.setState({ markupTypes: value });
    }

    toggleHumanEdited() {
        this.setState(({ showHumanEditedOnly }) => ({ showHumanEditedOnly: !showHumanEditedOnly }));
    }

    toggleEstimatedSpeakers() {
        this.setState(({ showEstimatedSpeakers }) => ({ showEstimatedSpeakers: !showEstimatedSpeakers }));
    }

    toggleTimestamps() {
        this.setState(({ showTimestamps }) => ({ showTimestamps: !showTimestamps }));
    }

    toggleSummary() {
        this.setState(({ showSummary }) => ({ showSummary: !showSummary }));
    }

    toggleTranslation() {
        this.setState(({ showTranslation }) => ({ showTranslation: !showTranslation }));
    }

    startAudio() {
        const { audioCall, audioCallId, mediaPlayer, reporter } = this.props;
        const isLive = !!get(mediaPlayer, 'isLive');

        reporter.track(reporter.actions.click, reporter.objects.audioPlay, {
            live: isLive,
            component: 'EventTab',
            eventId: audioCallId
        });
        this.setState({ loadingAudio: true }, () => {
            mediaPlayer
                .listen()
                .then(() => {
                    this.setState({ loadingAudio: false });
                })
                .catch(() => {
                    this.setState({ loadingAudio: false });
                });
            // If playing audio for the first time, seek to the first transcript item's startMs
            mediaPlayer.maybeSeekToFirstTranscriptItem(
                isLive,
                mediaPlayer.currentTime,
                get(audioCall, 'firstTranscriptItemStartMs')
            );
        });
    }

    stopAudio() {
        const { audioCallId, mediaPlayer, reporter } = this.props;
        if (mediaPlayer.seekable) {
            reporter.track(reporter.actions.click, reporter.objects.audioPause, {
                live: !!get(mediaPlayer, 'isLive'),
                component: 'EventTab',
                eventId: audioCallId
            });
            mediaPlayer.pause();
        } else {
            reporter.track(reporter.actions.click, reporter.objects.audioStop, {
                live: !!get(mediaPlayer, 'isLive'),
                component: 'EventTab',
                eventId: audioCallId
            });
            mediaPlayer.stop();
        }
    }

    render() {
        const {
            attachments,
            audioCall,
            audioCallId,
            disableQA,
            displayType,
            editedEvents,
            failureCode,
            focusMode,
            handleRef,
            hasPrivateRecordingAudio,
            hasSummary,
            highlightsFilterKey,
            highlightsSortKey,
            isEditing,
            isPublic,
            isPrivateRecording,
            loading,
            mediaPlayer,
            onKeywordSearch,
            onNodeSelect,
            onSearchClear,
            onTimeSelect,
            pathname,
            processingAudio,
            resultsIndex,
            reverseTranscripts,
            scrollToBottom,
            scrollToTop,
            searchResults,
            searchTerm,
            selectedEvent,
            selectedNode,
            setResultsIndex,
            shareId,
            streamId,
            streamMatches,
            styles,
            summaryAudioUrl,
            summaryText,
            summaryTitle,
            toggleFocus,
            toggleReverse,
            transcriptionStatus,
            uploadPercentComplete,
            uploadStatus,
            user
        } = this.props;
        const {
            issueReported,
            issueText,
            issues,
            loadingAudio,
            lockAudioScroll,
            markupTypes,
            showTranscriptVersionNotice,
            stickyHeight,
            showChart,
            showEstimatedSpeakers,
            showHumanEditedOnly,
            showSummary,
            showTimestamps,
            showTranslation
        } = this.state;

        return (
            <EventTranscriptsUI
                smartStatuses={get(audioCall, 'smartStatuses', [])}
                attachments={attachments}
                audioCallId={get(audioCall, 'id', audioCallId)}
                disableQA={disableQA}
                displayType={displayType}
                broadcastUrl={get(audioCall, 'broadcastUrl')}
                callDate={get(audioCall, 'callDate')}
                callType={get(audioCall, 'callType')}
                conferenceNumber={get(audioCall, 'conferenceNumber')}
                clearIssues={this.clearIssues}
                events={isEditing && showHumanEditedOnly ? editedEvents : get(audioCall, 'events')}
                expectPublishedTranscript={get(audioCall, 'expectPublishedTranscript')}
                failureCode={failureCode}
                firstTranscriptItemStartMs={get(audioCall, 'firstTranscriptItemStartMs')}
                focusMode={focusMode}
                handleRef={handleRef}
                hasPrivateRecordingAudio={hasPrivateRecordingAudio}
                hasPublishedTranscript={get(audioCall, 'hasPublishedTranscript')}
                hasSummary={hasSummary}
                hasTranslation={
                    get(audioCall, 'localeCode', 'en-US') !== 'en-US' &&
                    get(audioCall, 'transcriptCurrentVersion') === 0
                }
                hideTranscriptVersionNotice={this.hideTranscriptVersionNotice}
                highlightsFilterKey={highlightsFilterKey}
                highlightsSortKey={highlightsSortKey}
                inProgress={get(audioCall, 'isLive')}
                isEditing={isEditing}
                isEventOwner={get(user, 'id') === get(audioCall, 'createdByUserId')}
                isPublic={isPublic}
                isPrivateRecording={isPrivateRecording}
                issueReported={issueReported}
                issueText={issueText}
                issues={issues}
                loading={loading && !get(audioCall, 'events.length')}
                loadingAudio={loadingAudio}
                lockAudioScroll={lockAudioScroll}
                markupTypes={markupTypes}
                mediaPlayer={mediaPlayer}
                onKeywordSearch={onKeywordSearch}
                onNodeSelect={onNodeSelect}
                openUpgradeModal={this.openUpgradeModal}
                onSearchClear={onSearchClear}
                onTimeSelect={onTimeSelect}
                openSubmitDetails={this.openSubmitDetails}
                pathname={pathname}
                publishedTranscriptSource={get(audioCall, 'publishedTranscriptSource')}
                processingAudio={processingAudio}
                replayUrl={get(audioCall, 'replayUrl')}
                resultsIndex={resultsIndex}
                reverseTranscripts={reverseTranscripts}
                retryConnection={this.retryConnection}
                retryTranscription={this.retryTranscription}
                reportIssue={this.reportIssue}
                saveSettings={this.saveSettings}
                scrollToBottom={scrollToBottom}
                scrollToTop={scrollToTop}
                searchResults={searchResults}
                searchTerm={searchTerm}
                selectedEvent={selectedEvent}
                selectedNode={selectedNode}
                setIssueText={this.setIssueText}
                setIssue={this.setIssue}
                setResultsIndex={setResultsIndex}
                shareId={shareId}
                showChart={showChart}
                showEstimatedSpeakers={showEstimatedSpeakers}
                showHumanEditedOnly={showHumanEditedOnly}
                showSummary={showSummary}
                showTimestamps={showTimestamps}
                showTranslation={showTranslation}
                showTranscriptVersionNotice={showTranscriptVersionNotice}
                startAudio={this.startAudio}
                stopAudio={this.stopAudio}
                stickyHeight={stickyHeight}
                stickyRef={this.stickyRef}
                streamId={streamId}
                streamMatches={streamMatches}
                styles={styles}
                summaryAudioUrl={summaryAudioUrl}
                summaryText={summaryText}
                summaryTitle={summaryTitle}
                toggleAudioScroll={this.toggleAudioScroll}
                toggleChart={this.toggleChart}
                toggleEstimatedSpeakers={this.toggleEstimatedSpeakers}
                toggleFocus={toggleFocus}
                toggleMarkup={this.toggleMarkup}
                toggleReverse={toggleReverse}
                toggleHumanEdited={this.toggleHumanEdited}
                toggleSummary={this.toggleSummary}
                toggleTimestamps={this.toggleTimestamps}
                toggleTranslation={this.toggleTranslation}
                transcriptionStatus={transcriptionStatus}
                uploadPercentComplete={uploadPercentComplete}
                uploadStatus={uploadStatus}
                user={user}
                userId={get(user, 'id')}
            />
        );
    }
}

export const EventTranscriptsContainer = compose(
    connect(undefined, { setStatusBanner: statusBannerFire }),
    withUrlContext(['audioCallId', 'tabId', 'streamId', 'pathname']),
    withData(),
    withEventMediaPlayer({ allowLiveStream: true, fetchPolicy: 'cache-only' }),
    withUserPreferences(),
    withEventDetails({
        fetchPolicy: 'cache-only'
    }),
    withReporting(),
    withPropsOnChange(['audioCall'], ({ audioCall }) => ({
        editedEvents: get(audioCall, 'events', []).filter(({ isEdited }) => isEdited === true),
        failureCode: get(audioCall, 'failureCode'),
        processingAudio: get(audioCall, 'processingAudio', false)
    }))
)(EventTranscripts);
