import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import XDate from 'xdate';
import { Redirect } from 'react-router-dom';
import { compose, withPropsOnChange, withStateHandlers } from 'recompose';
import smoothscroll from 'smoothscroll-polyfill';
import { withUrlContext } from 'hoc/url';
import { withUserPreferences, withTrackUserActivity } from 'graphql/user';
import { withEventMediaPlayer, withEventDetails } from 'graphql/audioCalls';
import { PREFERENCES, PERMISSIONS } from 'consts';
import { externalRoutes, routes } from 'routes';
import {
    get,
    generateTabId,
    parseTabId,
    hasPreference,
    hasPermission,
    getPathParams,
    getPreference,
    getNativePrice,
    generateModalId,
    getCompanyQuotes
} from 'utils';
import { Scroller } from 'utils/scroll';
import { withData } from './data';
import { EventUI } from './ui';

// kick off the polyfill!
// for scrollTo in edge
if (/Edge/.test(navigator.userAgent)) {
    smoothscroll.polyfill();
}

const DATE_FORMAT = 'MMM dS, yyyy';

export class Event extends PureComponent {
    static displayName = 'EventContainer';

    static propTypes = {
        audioCall: PropTypes.objectOf(PropTypes.any),
        audioCalls: PropTypes.arrayOf(PropTypes.any),
        audioCallId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        audioCallStartPolling: PropTypes.func.isRequired,
        audioCallStopPolling: PropTypes.func.isRequired,
        callDate: PropTypes.string,
        callType: PropTypes.string,
        company: PropTypes.objectOf(PropTypes.any),
        connection: PropTypes.objectOf(PropTypes.any),
        createdByUserId: PropTypes.string,
        createTabPath: PropTypes.func.isRequired,
        disableCompany: PropTypes.bool,
        disableQA: PropTypes.bool,
        disableSidebar: PropTypes.bool,
        disableTitle: PropTypes.bool,
        displayType: PropTypes.string,
        events: PropTypes.arrayOf(PropTypes.object),
        hasCorpActivity: PropTypes.bool,
        hasFilings: PropTypes.bool,
        hasLiveTranscript: PropTypes.bool,
        hasPublishedTranscript: PropTypes.bool,
        highlightsFilterKey: PropTypes.string.isRequired,
        highlightsSortKey: PropTypes.string.isRequired,
        history: PropTypes.objectOf(PropTypes.any).isRequired,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        isEditing: PropTypes.bool.isRequired,
        isFocused: PropTypes.bool,
        isPublic: PropTypes.bool,
        isUploading: PropTypes.bool,
        keyMentions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        loadingAudioCalls: PropTypes.bool,
        loadingSentiment: PropTypes.bool,
        loadingSidebar: PropTypes.bool,
        localMonitorMatches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        mediaPlayer: PropTypes.objectOf(PropTypes.any).isRequired,
        monitorMatches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        page: PropTypes.string,
        pageId: PropTypes.string,
        pathname: PropTypes.string.isRequired,
        preferences: PropTypes.objectOf(PropTypes.any),
        priceHighlight: PropTypes.string,
        privateRecording: PropTypes.objectOf(PropTypes.any),
        redirectIfLoggedIn: PropTypes.bool,
        savePreference: PropTypes.func.isRequired,
        sidebarTab: PropTypes.string.isRequired,
        selectSidebarTab: PropTypes.func.isRequired,
        setHighlightsFilter: PropTypes.func.isRequired,
        setHighlightsSort: PropTypes.func.isRequired,
        setToolbarTitle: PropTypes.func,
        streamMatches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        tabId: PropTypes.string,
        tabs: PropTypes.arrayOf(PropTypes.any),
        title: PropTypes.string,
        tonalSentiment: PropTypes.objectOf(PropTypes.any),
        trackEventActivity: PropTypes.func.isRequired,
        transcriptEvents: PropTypes.arrayOf(PropTypes.object),
        transcriptionStatus: PropTypes.string,
        uploadInfo: PropTypes.shape({
            pctComplete: PropTypes.number,
            status: PropTypes.string
        }),
        uploadPercentComplete: PropTypes.number,
        uploadStatus: PropTypes.string,
        user: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        audioCall: null,
        audioCalls: [],
        audioCallId: null,
        callDate: null,
        callType: 'Unknown',
        company: undefined,
        connection: null,
        createdByUserId: null,
        disableCompany: false,
        disableQA: false,
        disableSidebar: false,
        disableTitle: false,
        displayType: 'default',
        events: [],
        hasCorpActivity: false,
        hasFilings: false,
        hasLiveTranscript: false,
        hasPublishedTranscript: false,
        id: null,
        isFocused: false,
        isPublic: false,
        isUploading: false,
        keyMentions: [],
        loadingAudioCalls: false,
        loadingSentiment: false,
        loadingSidebar: false,
        localMonitorMatches: [],
        monitorMatches: [],
        page: null,
        pageId: null,
        preferences: {},
        priceHighlight: undefined,
        privateRecording: undefined,
        redirectIfLoggedIn: true,
        setToolbarTitle: null,
        streamMatches: [],
        tonalSentiment: undefined,
        transcriptEvents: [],
        tabId: null,
        tabs: [],
        title: null,
        transcriptionStatus: undefined,
        uploadInfo: undefined,
        uploadPercentComplete: undefined,
        uploadStatus: undefined,
        user: null
    };

    constructor(props) {
        super(props);

        this.dismissPriceAlert = this.dismissPriceAlert.bind(this);
        this.handleArrowKeys = debounce(this.handleArrowKeys.bind(this));
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleKeywordSearch = debounce(this.handleKeywordSearch.bind(this), 250);
        this.handleMouseOut = this.handleMouseOut.bind(this);
        this.handleMouseOver = debounce(this.handleMouseOver.bind(this));
        this.handleRef = this.handleRef.bind(this);
        this.handleScrollContainerRef = this.handleScrollContainerRef.bind(this);
        this.onKeywordSearch = this.onKeywordSearch.bind(this);
        this.onNodeSelect = this.onNodeSelect.bind(this);
        this.onTimeSelect = this.onTimeSelect.bind(this);
        this.onContentTabSelect = this.onContentTabSelect.bind(this);
        this.onSearchClear = this.onSearchClear.bind(this);
        this.openEditEventModal = this.openEditEventModal.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.scrollToNode = this.scrollToNode.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.seekToAudio = this.seekToAudio.bind(this);
        this.seekToNode = this.seekToNode.bind(this);
        this.setResultsIndex = this.setResultsIndex.bind(this);
        this.toggleDialPad = this.toggleDialPad.bind(this);
        this.toggleFocus = this.toggleFocus.bind(this);
        this.toggleReverse = this.toggleReverse.bind(this);
        this.setupPollingCheck = this.setupPollingCheck.bind(this);
        this.clearPolling = this.clearPolling.bind(this);

        this.checkedEventIds = new Set();
        this.documentTitle = document.title;
        this.eventRef = createRef();
        this.scroller = new Scroller();
        this.transcriptNodes = {};

        this.state = {
            attemptingNetworkRequest: false,
            attemptedNetworkRequest: false,
            dismissPriceAlert: false,
            eventInFocus: false,
            resultsIndex: 0,
            searchResults: null,
            searchTerm: '',
            selectedEvent: null,
            selectedNode: null,
            selectedContentTab: 'transcript',
            showCustomGate: true,
            showDialPad: false,
            showPriceAlert: false,
            isPolling: false,
            startPollingTimeout: null,
            endPollingTimeout: null
        };
    }

    componentDidMount() {
        const { trackEventActivity, audioCallId } = this.props;
        if (audioCallId) {
            trackEventActivity(audioCallId);
        }
        document.addEventListener('keydown', this.handleKeyDown);

        if (this.inProgress() && !this.shouldReverse()) {
            this.scroller.scrollToBottom();
        }

        // We want this tab in focus so selecting text
        // works on first try
        if (this.eventRef.current) {
            this.eventRef.current.focus();
        }

        this.setupPollingCheck();
    }

    componentDidUpdate(prevProps) {
        const {
            callDate: prevCallDate,
            loadingAudioCalls: prevLoading,
            priceHighlight: prevPriceHighlight,
            transcriptionStatus: prevStatus
        } = prevProps;
        const {
            callDate,
            mediaPlayer,
            tabId,
            audioCallId,
            loadingAudioCalls,
            events,
            trackEventActivity,
            priceHighlight,
            title,
            transcriptionStatus
        } = this.props;
        const { selectedNode, attemptedNetworkRequest, attemptingNetworkRequest } = this.state;
        let hasStateUpdate = false;
        const newState = {};

        // Set the page title once the title loads
        if (prevProps.title !== title) {
            document.title = `Aiera | Events | ${title}`;
        }

        if (audioCallId && prevProps.audioCallId !== audioCallId) {
            this.transcriptNodes = {};
            trackEventActivity(audioCallId);
        }

        this.maybeUpdateTitle();
        if (!selectedNode || prevProps.tabId !== tabId) {
            this.maybeScrollToTermMatch();
        }

        const initialTranscriptLoad =
            !get(prevProps, 'events.length', 0) && !loadingAudioCalls && get(events, 'length', 0);

        const selection = window.getSelection();
        const hasSelection = selection?.toString()?.length > 0;
        if (!hasSelection && this.inProgress() && !this.shouldReverse()) {
            if (initialTranscriptLoad) {
                this.scroller.scrollToBottom();
            }
            this.scroller.startAutoScroll();
        } else {
            this.scroller.stopAutoScroll();
        }

        // Was not loading, and now is loading - set attempting state
        if (!prevLoading && loadingAudioCalls && !attemptingNetworkRequest) {
            this.setState({ attemptingNetworkRequest: true });
        }

        // Was loading, and is now not loading, and is still attempting - set attempted state
        if (prevLoading && !loadingAudioCalls && attemptingNetworkRequest && !attemptedNetworkRequest) {
            this.setState({ attemptedNetworkRequest: true, attemptingNetworkRequest: false });
        }

        // Only show alert if there was no price highlight
        // after loading had already finished
        // and there is now a price highlight
        if (!prevLoading && !prevPriceHighlight && !!priceHighlight) {
            // hasStateUpdate = true;
            // newState.showPriceAlert = true;
            // TODO This has not ever worked
            // we should fix this
        }

        if (prevStatus !== transcriptionStatus && ['archived', 'finished'].includes(transcriptionStatus)) {
            hasStateUpdate = true;
            newState.showCustomGate = false;
        }

        if (hasStateUpdate) {
            this.setState({ ...newState });
        }

        // We want this tab in focus so selecting text
        // works on first try
        if (this.eventRef.current) {
            this.eventRef.current.focus();
        }

        if (mediaPlayer.isReady && !prevProps.mediaPlayer.isReady) {
            mediaPlayer.on('seek', this.seekToNode);
        }

        if (prevCallDate !== callDate) {
            this.clearPolling();
            this.setupPollingCheck();
        }
    }

    componentWillUnmount() {
        document.title = this.documentTitle; // reset page title
        this.scroller.cleanup();
        document.removeEventListener('keydown', this.handleKeyDown);
        this.clearPolling();
    }

    clearPolling() {
        const { startPollingTimeout, endPollingTimeout } = this.state;
        if (startPollingTimeout) clearTimeout(startPollingTimeout);
        if (endPollingTimeout) clearTimeout(endPollingTimeout);
    }

    setupPollingCheck() {
        const { audioCallStartPolling, audioCallStopPolling, callDate } = this.props;
        const { isPolling } = this.state;
        if (!callDate) return;

        const callDateTime = new Date(callDate);
        const now = new Date();
        const timeDiff = callDateTime - now;

        // Calculate when to start polling (15 mins before call)
        const startTime = timeDiff - 15 * 60 * 1000;
        // Calculate when to end polling (30 mins after call)
        const endTime = timeDiff + 30 * 60 * 1000;

        if (startTime > 0) {
            // Set timer to start polling
            const startPollingTimeout = setTimeout(() => {
                if (!isPolling) {
                    this.setState({ isPolling: true });
                    audioCallStartPolling(60000);
                }
            }, startTime);
            this.setState({ startPollingTimeout });
        } else if (startTime <= 0 && endTime > 0) {
            // We're in the polling window - start immediately
            if (!isPolling) {
                this.setState({ isPolling: true });
                audioCallStartPolling(60000);
            }
        }

        if (endTime > 0) {
            // Set timer to end polling
            const endPollingTimeout = setTimeout(() => {
                if (isPolling) {
                    this.setState({ isPolling: false });
                    audioCallStopPolling();
                }
            }, endTime);
            this.setState({ endPollingTimeout });
        }
    }

    dismissPriceAlert() {
        this.setState({ dismissPriceAlert: true, showPriceAlert: false });
    }

    handleArrowKeys(direction) {
        const { events } = this.props;
        const { selectedNode } = this.state;
        const goingUp = direction === 'up';
        let nextIndex = null;
        for (let i = 0; i < events.length; i += 1) {
            const currentId = events[i].id;
            if (currentId === selectedNode) {
                if (goingUp) {
                    nextIndex = events[i - 1] ? events[i - 1].id : selectedNode;
                } else {
                    nextIndex = events[i + 1] ? events[i + 1].id : selectedNode;
                }
            }

            if (nextIndex) {
                this.onNodeSelect({ id: nextIndex, withScroll: true });
                break;
            }
        }
    }

    handleKeyDown(event) {
        const { keyCode, code } = event;
        const { selectedNode, eventInFocus } = this.state;
        const { tabs } = this.props;
        const isFocused = eventInFocus || tabs.length < 2;
        if (isFocused && selectedNode) {
            const goingUp = keyCode === 38 || code === 'ArrowUp';
            const goingDown = keyCode === 40 || code === 'ArrowDown';

            if (goingUp || goingDown) {
                event.preventDefault();
                this.handleArrowKeys(goingUp ? 'up' : 'down');
            }
        }
    }

    handleKeywordSearch(e) {
        const { searchResults: results } = this.state;
        let searchResults = results;
        const searchTerm = get(e, 'target.value', '');

        // Only do the keyword lookup if the search term is at least 2 chars
        if (searchTerm.length > 1) {
            searchResults = [];
            const { transcriptEvents } = this.props;
            // Set the results as an array of event ids that have a transcript matching the search term
            searchResults = transcriptEvents
                .filter(event => {
                    const { transcript } = event;
                    return transcript && transcript.toLowerCase().includes(searchTerm.toLowerCase());
                })
                .map(({ eventId }) => eventId);
        } else {
            searchResults = null;
        }

        this.setState(
            {
                resultsIndex: 0,
                searchTerm,
                searchResults
            },
            () => {
                if (searchResults && searchResults.length) {
                    this.onNodeSelect({ id: searchResults[0], withScroll: true });
                }
            }
        );
    }

    handleMouseOut() {
        const { eventInFocus } = this.state;
        if (eventInFocus) {
            this.setState({
                eventInFocus: false
            });
        }
    }

    handleMouseOver() {
        const { eventInFocus } = this.state;
        if (!eventInFocus) {
            this.setState({
                eventInFocus: true
            });
        }
    }

    handleRef(id, node) {
        this.transcriptNodes[id] = node;
    }

    handleScrollContainerRef(node) {
        this.scroller.setScrollContainer(node);
    }

    inProgress() {
        const { audioCall } = this.props;
        return get(audioCall, 'isLive');
    }

    maybeScrollToTermMatch() {
        const { tabId, page, pageId, selectSidebarTab, sidebarTab } = this.props;
        const { selectedNode } = this.state;
        const { match, itemId } = parseTabId(tabId);

        if (page === 'text' && pageId && pageId !== selectedNode && Object.keys(this.transcriptNodes).length > 0) {
            this.onNodeSelect({ id: pageId.toString(), withScroll: true });
        } else if (match && itemId && Object.keys(this.transcriptNodes).length > 0) {
            this.onNodeSelect({ id: itemId.toString(), withScroll: true, isTermMatch: true });
            if (sidebarTab === 'highlights') {
                selectSidebarTab('keyMentions');
            }
        }
    }

    maybeUpdateTitle() {
        const { callDate, callType, company, title, setToolbarTitle, loadingAudioCalls, audioCalls } = this.props;
        const noEventFound = !loadingAudioCalls && (!audioCalls || audioCalls.length <= 0);
        const name = get(company, 'commonName');
        let pageTitle = title || (noEventFound ? 'Not found' : 'Loading...');
        const isCall = pageTitle.toLowerCase().includes('call');
        let date = '';

        if (name && callType && callDate) {
            date = `${new XDate(callDate).toString(DATE_FORMAT)}`;
            const callTitle = title ? `${name} — ${title}` : `${name} ${callType} call - ${date}`;
            pageTitle = isCall ? callTitle : `${name} — ${title}`;
        }

        if (setToolbarTitle) {
            setToolbarTitle(pageTitle);
        }
    }

    onContentTabSelect(selectedContentTab) {
        this.setState({
            selectedContentTab
        });
    }

    onKeywordSearch(e) {
        // this runs on every keystroke, but handleKeywordSearch is debounced
        // so it'll only run once the user stops typing or every 250ms
        e.persist();
        this.handleKeywordSearch(e);
    }

    onNodeSelect({ id, withScroll = false, isTermMatch = false, seekToAudio = false, streamId }) {
        const { transcriptEvents, pageId, history, createTabPath, audioCall } = this.props;
        const { selectedContentTab } = this.state;
        const selectedEvent = transcriptEvents.find(e => e.eventId.toString() === id.toString());

        const selectNode = () => {
            const node = this.transcriptNodes[id];
            if (node) {
                this.setState(
                    {
                        selectedNode: id,
                        selectedEvent
                    },
                    () => {
                        if (seekToAudio) {
                            this.seekToAudio();
                        }

                        if (withScroll) {
                            this.scrollToNode(node);
                        }

                        if (pageId !== id && (!isTermMatch || streamId)) {
                            let tabPath = generateTabId({
                                audioCallId: get(audioCall, 'id'),
                                page: isTermMatch ? 'match' : 'text',
                                pageId: id
                            });

                            if (streamId && isTermMatch) {
                                tabPath = generateTabId({
                                    audioCallId: get(audioCall, 'id'),
                                    itemId: id,
                                    match: true,
                                    streamId
                                });
                            }
                            setTimeout(() => {
                                history.push(createTabPath(tabPath));
                            });
                        }
                    }
                );
            }
        };

        if (selectedEvent && selectedContentTab !== 'transcript') {
            // There is no "node" to scroll to
            // if the "transcript" tab is not selected.
            // This is split into two state calls so the
            // transcript tab is rendered and the node exists.
            // We should also make sure the event item exists,
            // as it may have been deleted
            this.setState(
                {
                    selectedContentTab: 'transcript'
                },
                selectNode
            );
        } else {
            selectNode();
        }
    }

    onSearchClear() {
        // Reset the search state
        this.setState({
            searchTerm: '',
            searchResults: null,
            resultsIndex: 0
        });
    }

    onTimeSelect(time) {
        const { transcriptEvents } = this.props;
        const transcripts = transcriptEvents.filter(e => e.eventType === 'transcript');
        const idx = transcripts.findIndex(e => (e.startTimestamp ? new XDate(e.startTimestamp) > time : false));
        const event = transcripts[idx];
        const prevEvent = transcripts[idx - 1];
        if (prevEvent) {
            const prevDistance = time - new XDate(prevEvent.startTimestamp || prevEvent.createdDate);
            const distance = new XDate(event.startTimestamp || event.createdDate) - time;
            this.onNodeSelect({ id: prevDistance < distance ? prevEvent.eventId : event.eventId, withScroll: true });
        } else if (event) {
            this.onNodeSelect({ id: event.eventId, withScroll: true });
        } else if (transcripts.length) {
            this.onNodeSelect({ id: transcripts.slice(-1)[0].eventId, withScroll: true });
        }
    }

    openEditEventModal() {
        const { history, pathname, user, audioCallId } = this.props;
        if (hasPermission(user, PERMISSIONS.unlockedCreatePrivateRecording)) {
            history.push(
                generateModalId({
                    pathname,
                    id: audioCallId,
                    type: 'privateRecording',
                    tabId: generateTabId({ audioCallId })
                })
            );
        } else {
            history.push(
                generateModalId({ pathname, id: 'createEvent', type: 'upgrade', tabId: generateTabId({ audioCallId }) })
            );
        }
    }

    scrollToBottom() {
        this.scroller.scrollToBottom();
    }

    scrollToNode(element) {
        const { hasPublishedTranscript } = this.props;
        const dims = element.getBoundingClientRect();
        const { top } = dims;
        let topPadding = 140;
        if (hasPublishedTranscript) {
            topPadding += 82;
        }
        if (this.eventRef.current) {
            const stickyHeader = this.eventRef.current.querySelector('.event-sticky-container');
            if (stickyHeader) {
                topPadding += stickyHeader.clientHeight || 0;
            }
        }
        const scrollVal = this.scroller.scrollTop + top - topPadding;
        this.scroller.scrollTo({ top: scrollVal });
    }

    scrollToTop() {
        this.scroller.scrollToTop();
    }

    seekToAudio() {
        const { selectedNode } = this.state;
        const { transcriptEvents, mediaPlayer } = this.props;

        // Get transcription event
        const item = transcriptEvents.find(i => i.id.toString() === selectedNode.toString());

        // Get start time of transcript event, and offset from event start time
        const startMs = Math.max(parseInt(get(item, 'startMs', 0), 10) / 1000, 0);

        // This is called from onNodeSelect.. so when the nodes change
        // The last argument, "false" is to prevent emiting an event
        // which would call the didUpdate method, and call the seekToNode
        // function, creating an infinite loop.
        // For live calls, go back 1 extra second since the HLS file tends to add
        // a small offset
        mediaPlayer.seek(startMs - (this.inProgress() ? 1 : 0), true, false, true);
    }

    seekToNode(timeInfo) {
        const { transcriptEvents } = this.props;
        // Use the raw audio time since we are comparing to startMs from GS/Twilio
        const { raw: currentTime, live } = timeInfo;

        if (live) {
            if (this.shouldReverse()) {
                this.scroller.scrollToTop();
            } else {
                this.scroller.scrollToBottom();
            }
        } else if (currentTime > 0) {
            // Get transcription event
            // by finding the first time
            // the event is after the selected time

            const nodeIndex = transcriptEvents.findIndex(item => {
                return currentTime < Math.max(parseInt(get(item, 'startMs', 0), 10) / 1000, 0);
            });
            // choose the event prior, so we haven't missed the audio
            const node = transcriptEvents[nodeIndex - 1];

            if (node) {
                // This tells the function not to call "seektoAudio", which
                // could create an infinite loop
                this.onNodeSelect({
                    id: get(node, 'eventId'),
                    withScroll: true,
                    isTermMatch: false,
                    seekToAudio: false
                });
            }
        }
    }

    setResultsIndex(resultsIndex) {
        const { searchResults } = this.state;
        const eventId = searchResults[resultsIndex];
        const node = this.transcriptNodes[eventId];
        if (node) {
            this.setState(
                {
                    resultsIndex
                },
                () => this.onNodeSelect({ id: eventId, withScroll: true })
            );
        }
    }

    shouldReverse() {
        const { preferences } = this.props;
        return (
            this.inProgress() &&
            hasPreference(preferences, { ...PREFERENCES.reverseLiveTranscripts, value: true }, true)
        );
    }

    toggleDialPad() {
        this.setState(state => ({ showDialPad: !state.showDialPad, showCustomGate: false }));
    }

    toggleFocus() {
        const { savePreference, preferences } = this.props;
        const pref = PREFERENCES.eventFocusMode;
        const focusMode = !getPreference(preferences, pref, true);
        savePreference(pref.type, { ...pref, value: focusMode });
    }

    toggleReverse() {
        const { savePreference, preferences } = this.props;
        const pref = PREFERENCES.reverseLiveTranscripts;
        const reverse = !getPreference(preferences, pref, true);
        savePreference(pref.type, { ...pref, value: reverse }).then(() => {
            if (reverse) {
                this.scroller.scrollToTop();
            } else {
                this.scroller.scrollToBottom();
            }
        });
    }

    render() {
        const {
            audioCall,
            callDate,
            callType,
            connection,
            createdByUserId,
            disableCompany,
            disableQA,
            disableSidebar,
            disableTitle,
            displayType,
            hasCorpActivity,
            hasFilings,
            hasLiveTranscript,
            hasPublishedTranscript,
            highlightsFilterKey,
            highlightsSortKey,
            id,
            isEditing,
            isFocused,
            isPublic,
            isUploading,
            keyMentions,
            loadingSentiment,
            loadingSidebar,
            localMonitorMatches,
            monitorMatches,
            privateRecording,
            redirectIfLoggedIn,
            selectSidebarTab,
            setHighlightsFilter,
            setHighlightsSort,
            sidebarTab,
            tonalSentiment,
            transcriptEvents,
            transcriptionStatus,
            uploadInfo,
            uploadPercentComplete,
            uploadStatus,
            user,
            ...rest
        } = this.props;
        const {
            attemptedNetworkRequest,
            dismissPriceAlert,
            resultsIndex,
            searchResults,
            searchTerm,
            selectedContentTab,
            selectedEvent,
            selectedNode,
            showCustomGate,
            showDialPad,
            showPriceAlert,
            streamMatches
        } = this.state;

        if (redirectIfLoggedIn && isPublic && user && user.userId && id) {
            return <Redirect to={`${routes.dashboardHome}?tabs[0]=${generateTabId({ eventId: id })}`} />;
        }

        const loadingAudioCalls = get(this.props, 'loadingAudioCalls', false);
        const hasEvent = get(this.props, 'audioCalls', []).length > 0;
        // PUT OTHER PROPS AFTER {...rest}
        return (
            <EventUI
                audioCall={audioCall}
                callDate={callDate}
                callType={callType}
                disableCompany={disableCompany}
                disableQA={disableQA}
                disableSidebar={disableSidebar}
                disableTitle={disableTitle}
                dismissPriceAlert={this.dismissPriceAlert}
                displayType={displayType}
                eventRef={this.eventRef}
                eventNotFound={attemptedNetworkRequest && !loadingAudioCalls && !hasEvent}
                focusMode={isFocused}
                handleMouseOut={this.handleMouseOut}
                handleMouseOver={this.handleMouseOver}
                handleRef={this.handleRef}
                handleScrollContainerRef={this.handleScrollContainerRef}
                hasCorpActivity={hasCorpActivity}
                hasFilings={hasFilings}
                hasLiveTranscript={hasLiveTranscript}
                hasPublishedTranscript={hasPublishedTranscript}
                highlightsFilterKey={highlightsFilterKey}
                highlightsSortKey={highlightsSortKey}
                id={id}
                inProgress={this.inProgress()}
                isEditing={isEditing}
                isPublic={isPublic}
                isUploading={isUploading}
                keyMentions={keyMentions}
                loadingSidebar={loadingSentiment || loadingSidebar}
                localMonitorMatches={localMonitorMatches}
                monitorMatches={monitorMatches}
                onContentTabSelect={this.onContentTabSelect}
                onKeywordSearch={this.onKeywordSearch}
                onNodeSelect={this.onNodeSelect}
                onSearchClear={this.onSearchClear}
                onTimeSelect={this.onTimeSelect}
                openEditEventModal={this.openEditEventModal}
                privateRecording={privateRecording}
                resultsIndex={resultsIndex}
                reverseTranscripts={this.shouldReverse()}
                searchResults={searchResults}
                searchTerm={searchTerm}
                selectedContentTab={selectedContentTab}
                selectedEvent={selectedEvent}
                selectedNode={selectedNode}
                selectSidebarTab={selectSidebarTab}
                setHighlightsFilter={setHighlightsFilter}
                setHighlightsSort={setHighlightsSort}
                setResultsIndex={this.setResultsIndex}
                sidebarTab={sidebarTab}
                showCustomGate={showCustomGate}
                scrollToBottom={this.scrollToBottom}
                scrollToTop={this.scrollToTop}
                showDialPad={showDialPad}
                showPriceAlert={showPriceAlert && !dismissPriceAlert}
                streamMatches={streamMatches}
                toggleDialPad={this.toggleDialPad}
                toggleFocus={this.toggleFocus}
                toggleReverse={this.toggleReverse}
                tonalSentiment={tonalSentiment}
                transcriptEvents={transcriptEvents}
                transcriptNodes={this.transcriptNodes}
                transcriptionStatus={transcriptionStatus}
                uploadPercentComplete={uploadPercentComplete || get(uploadInfo, 'pctComplete')}
                uploadStatus={uploadStatus || get(uploadInfo, 'status')}
                user={user}
                {...rest}
            />
        );
    }
}

export const EventContainer = compose(
    withUrlContext([
        'history',
        'pathname',
        'audioCallId',
        'eventId',
        'tabs',
        'streamId',
        'index',
        'page',
        'pageId',
        'createTabPath'
    ]),
    withTrackUserActivity(),
    withUserPreferences({ fetchPolicy: 'cache-first', variables: { withDetails: true } }),
    withEventDetails({
        subscribe: true,
        variables: {
            withCompany: true,
            withPrivateRecording: true,
            withUploadInfo: true
        }
    }),
    withPropsOnChange(
        ['audioCallId', 'audioCallsLoading', 'eventId', 'user'],
        ({ audioCall, audioCallsLoading, audioCallId, eventId }) => ({
            loadingAudioCalls: audioCallsLoading, // withEventMediaPlayer also has audioCallsLoading, which will be false
            company: get(audioCall, 'primaryCompany'),
            eventId: eventId || audioCallId
        })
    ),
    withPropsOnChange(['audioCall', 'pathname'], ({ audioCall, pathname, user }) => {
        const { events } = audioCall || {};
        const hasCorpActivity = get(audioCall, 'spotlightContent', []).length > 0;
        const hasFilings = get(audioCall, 'filingContent', []).length > 0;
        const instruments = get(audioCall, 'company.instruments', []);
        const { primaryQuote } = getCompanyQuotes(instruments);
        const currency = get(primaryQuote, 'currency');
        const priceHighlightMovementPercent = get(audioCall, 'priceHighlight.movementPercent')
            ? get(audioCall, 'priceHighlight.movementPercent') * 100
            : undefined;
        const priceHighlightMovementAbsolute = get(audioCall, 'priceHighlight.movementAbsolute');
        const pathParams = getPathParams(pathname, { path: externalRoutes.publicEvent });
        const embedPathParams = getPathParams(pathname, { path: externalRoutes.publicEmbedEvent });
        const shareId = get(audioCall, 'shareId', get(pathParams, 'shareId', get(embedPathParams, 'shareId')));
        const isEditing = get(audioCall, 'editingQueue.editStatus') === 'in_progress';
        let priceHighlight = get(audioCall, 'priceHighlight.endOrLatestPrice');
        if (priceHighlight) {
            priceHighlight = getNativePrice({ price: priceHighlight, currency });
        }
        return {
            ...audioCall,
            hasCorpActivity,
            hasFilings,
            hasLiveTranscript: get(audioCall, 'hasLiveTranscript', false),
            isEditing,
            isEventOwner: get(user, 'id', null) === get(audioCall, 'createdByUserId'),
            priceHighlight,
            priceHighlightMovementAbsolute,
            priceHighlightMovementPercent,
            privateRecording: get(audioCall, 'privateRecording'),
            shareId,
            transcriptEvents: events
        };
    }),
    withEventMediaPlayer(),
    withStateHandlers(
        () => ({
            sidebarTab: 'keyMentions',
            highlightsFilterKey: 'all',
            highlightsSortKey: 'chron'
        }),
        {
            selectSidebarTab: () => sidebarTab => ({
                sidebarTab
            }),
            setHighlightsFilter: () => ({ value }) => ({
                highlightsFilterKey: value
            }),
            setHighlightsSort: () => ({ value }) => ({
                highlightsSortKey: value
            })
        }
    ),
    withData()
)(Event);
