import gql from 'graphql-tag';
import set from 'lodash/set';
import sortBy from 'lodash/sortBy';
import filterBy from 'lodash/filter';
import maxBy from 'lodash/maxBy';
import XDate from 'xdate';
import memoize from 'memoize-one';
import { connect } from 'react-redux';
import { compose, lifecycle, withProps, toRenderProps, withStateHandlers } from 'recompose';
import { AMP_BASE_URL_DEV, AMP_BASE_URL_PROD, config } from 'configuration';
import { PERMISSIONS } from 'consts';
import { generateTabId, hasPermission, makeCancelable, get } from 'utils';
import { MediaPlayer } from 'utils/media';
import { mobileBridge } from 'provider/mobileBridge';
import { withRealtime } from 'provider/realtime';
import { withReporting } from 'provider/reporting';
import { registerMediaEvents, unregisterMediaEvents } from 'hoc/media';
import { mergeOptions, mapPropsToOptions } from 'hoc/utils';
import { graphql } from 'graphql/utils';
import {
    withUser,
    withTrackUserActivity,
    addUserFinancialKpiMutation,
    replaceUserFinancialKpiMutation,
    removeUserFinancialKpiMutation
} from 'graphql/user';
import { statusBannerFire } from 'actions/statusBanner';
import {
    scheduledAudioCallEventFragment,
    scheduledAudioCallFragment,
    differentialFragment,
    scheduledAudioCallStateFragment
} from 'graphql/fragments/audioCalls';

export const createEventMutation = gql`
    mutation CreateEvent($input: CreateEventInput!) {
        createEvent(input: $input) {
            success
            event {
                ...scheduledAudioCall
            }
        }
    }
    ${scheduledAudioCallFragment}
`;

export const updateEventMutation = gql`
    mutation UpdateEvent($input: UpdateEventInput!) {
        updateEvent(input: $input) {
            success
            event {
                ...scheduledAudioCall
            }
        }
    }
    ${scheduledAudioCallFragment}
`;

export const deleteEventMutation = gql`
    mutation DeleteEvent($eventId: ID!) {
        deleteEvent(eventId: $eventId) {
            success
            event {
                id
                deleted
            }
        }
    }
`;

export const updateEventItemMutation = gql`
    mutation UpdateEventItem($input: UpdateEventItemInput!) {
        updateEventItem(input: $input) {
            success
            item {
                ...scheduledAudioCallEvent
            }
        }
    }
    ${scheduledAudioCallEventFragment}
`;

export const deleteEventItemMutation = gql`
    mutation DeleteEventItem($itemId: ID!) {
        deleteEventItem(itemId: $itemId) {
            success
        }
    }
`;

export const getAudioCallsQuery = alias => gql`
    query ${alias || 'GetAudioCalls'}(
        $callIds: [ID]
        $filter: ScheduledAudioCallFilter
        $shareId: ID
        $afterItemId: ID
        $itemId: ID
        $withCompany: Boolean = false
        $withEventDetails: Boolean = false
        $withTranscript: Boolean = false
        $withDifferentials: Boolean = false
        $withPrivateRecording: Boolean = false
        $withEventSentiment: Boolean = false
        $withUploadInfo: Boolean = false
    ) {
        audioCalls: events(eventIds: $callIds, filter: $filter, shareId: $shareId) {
            ...scheduledAudioCall
            #
            # Passing variables to fragments is still experimental
            # so the event details are just part fo the main query
            # for now.
            #
            primaryCompany @include(if: $withCompany) {
                id
                commonName
                iconUrl
                instruments {
                    id
                    isPrimary
                    quotes {
                        id
                        currency {
                            id
                            currencyCode
                            minorSymbol
                            minorSymbolPrefix
                            symbol
                            symbolPrefix
                        }
                        exchange {
                            id
                            country {
                                id
                                countryCode
                            }
                            shortName
                        }
                        isPrimary
                        localTicker
                    }
                }
            }
            equity {
                id
                equityId
                localTicker
                name
                commonName
                exchange {
                    id
                    shortName
                }
                currency {
                    id
                    currencyCode
                    symbol
                    symbolPrefix
                    minorSymbol
                    minorSymbolPrefix
                }
                financialKpis @include(if: $withDifferentials) {
                    title
                    category
                    key
                    synonyms
                }
            }
            attachments @include(if: $withEventDetails) {
                title
                mimeType
                url
                archivedUrl
            }
            editingQueue @include(if: $withEventDetails) {
                id
                editStatus
            }
            events: items(afterItemId: $afterItemId, itemId: $itemId) @include(if: $withTranscript) {
                ...scheduledAudioCallEvent
                speakerTurn @include(if: $withEventSentiment) {
                    id: speakerTurnId
                    isSentimentDivergent
                    textSentiment {
                        score
                    }
                    tonalSentiment {
                        score
                    }
                }
            }
            differentials @include(if: $withDifferentials) {
                ...differential
            }
            eventGroups @include(if: $withEventDetails) {
                id
                promoted
                title
            }
            firstTranscriptItemStartMs @include(if: $withEventDetails)
            userSettings @include(if: $withEventDetails) {
                id
                shareBookmarks
                isRead
                archived
                starred
                tags
            }
            tags @include(if: $withEventDetails){
                tag
                users {
                    id
                    username
                }
            }
            spotlightContent: content(filter: { contentTypes: [spotlight] }) @include(if: $withEventDetails) {
                id
                contentType
                displayType
                title
                publishedDate
                ... on GuidanceSpotlightContent {
                    spotlightSubtype: guidanceTrend
                    eventDate
                }
                ... on PartnershipSpotlightContent {
                    eventDate
                }
                ... on AssetPurchaseSpotlightContent {
                    eventDate
                }
                ... on BuybackSpotlightContent {
                    eventDate
                }
                ... on SalesMetricSpotlightContent {
                    eventDate
                }
                ... on MAndASpotlightContent {
                    eventDate
                }
                ... on SpinOffSpotlightContent {
                    eventDate
                }
                ... on IPOSpotlightContent {
                    eventDate
                }
            }
            filingContent: content(filter: { contentTypes: [filing] }) @include(if: $withEventDetails) {
                id
                contentType
                displayType
                title
                publishedDate
                ... on FilingContent {
                    filing {
                        id
                        form {
                            id
                            formName
                            formNameShort
                            formNumber
                        }
                        periodEndDate
                        releaseDate
                        arrivalDate
                        isAmendment
                        officialUrl
                    }
                }
            }
            privateRecording @include(if: $withPrivateRecording) {
                id
                connectionType
            }
            uploadInfo @include(if: $withUploadInfo) {
                pctComplete
                status
            }
            smartStatuses {
                relatedEventId
                id
                created
                content
                isPublic
            }
        }
    }
    ${scheduledAudioCallFragment}
    ${scheduledAudioCallEventFragment}
    ${differentialFragment}
`;

function mergeEvents(prevEvents, newEvents) {
    const eventMap = prevEvents.reduce((map, event) => Object.assign(map, { [event.id]: event }), {});
    newEvents.forEach(event => {
        eventMap[event.id] = event;
    });
    return sortBy(
        filterBy(Object.values(eventMap), event => get(event, 'status') !== 'deleted'),
        e => e.startTimestamp || e.createdDate
    );
}

function parsedFilters(filters) {
    // Remove props with falsy values before sending to the server
    return filters
        ? Object.keys(filters)
              .filter(key => filters[key] !== undefined || filters[key] !== null)
              .reduce((prev, key) => {
                  const newObj = { ...prev };
                  newObj[key] = filters[key];
                  return newObj;
              }, {})
        : {};
}

// Pure apollo hoc, broken out just to keep it from being one massive HOC but
// this probably doesn't need to be used anywhere directly, use withAudioCalls
// instead
export const withAudioCalls = (options = {}) => {
    const { skip, alias = 'withAudioCalls' } = mapPropsToOptions(options);

    return graphql(getAudioCallsQuery(alias), {
        props: ({ data }) => ({
            audioCallsRefresh: data.refetch,
            audioCallsFetchMore: data.fetchMore,
            audioCallStartPolling: data.startPolling,
            audioCallStopPolling: data.stopPolling,
            audioCallsError: data.error,
            audioCallsLoading: !!data.loading,
            audioCalls: data.audioCalls,
            audioCall: data.audioCalls && data.audioCalls.length === 1 ? data.audioCalls[0] : null
        }),
        alias,
        skip,
        options: props => {
            const opts = mapPropsToOptions(options, props);
            const { filters } = props;
            return {
                fetchPolicy: opts.fetchPolicy || 'cache-and-network',
                errorPolicy: opts.errorPolicy || 'all',
                variables: {
                    callIds:
                        (props.audioCallId && [props.audioCallId]) ||
                        props.audioCallIds ||
                        (props.eventId && [props.eventId]) ||
                        props.eventIds,
                    filter: Object.keys(parsedFilters(filters)).length > 0 ? { ...parsedFilters(filters) } : undefined,
                    shareId: props.shareId,
                    withCompany: false,
                    withDifferentials: false,
                    withEventDetails: false,
                    withEventSentiment: false,
                    withPrivateRecording: false,
                    withTranscript: false,
                    withUploadInfo: false,
                    ...(opts.variables || {})
                },
                context: opts.context
            };
        }
    });
};

export const withCreateEvent = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(createEventMutation, {
            props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                createEvent: input =>
                    mutate({ variables: { input } })
                        .then(({ data }) => {
                            const event = get(data, 'createEvent.event');
                            setStatusBanner(`Event ${get(event, 'title', '')} created successfully!`);
                            return event;
                        })
                        .catch(e => {
                            setStatusBanner(
                                "We weren't able to create your event. Please refresh the page and try again.",
                                'error',
                                'circleX'
                            );
                            throw e;
                        })
            })
        }),
        graphql(updateEventMutation, {
            props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                updateEvent: input =>
                    mutate({ variables: { input } })
                        .then(({ data }) => {
                            const event = get(data, 'updateEvent.event');
                            setStatusBanner(`Event ${get(event, 'title', '')} updated successfully!`);
                            return event;
                        })
                        .catch(e => {
                            setStatusBanner(
                                "We weren't able to update your event. Please refresh the page and try again.",
                                'error',
                                'circleX'
                            );
                            throw e;
                        })
            })
        }),
        graphql(deleteEventMutation, {
            props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                deleteEvent: eventId =>
                    mutate({
                        variables: { eventId }
                    })
                        .then(({ data }) => {
                            setStatusBanner('Event removed successfully!');
                            return get(data, 'deleteEvent.success');
                        })
                        .catch(e => {
                            setStatusBanner(
                                "We weren't able to remove your event. Please refresh the page and try again.",
                                'error',
                                'circleX'
                            );
                            throw e;
                        })
            })
        })
    );

export const withDisconnectEvent = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation DisconnectCall($eventId: ID!) {
                    disconnectCall(scheduledAudioCallId: $eventId) {
                        success
                    }
                }
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                    disconnectCall: eventId =>
                        mutate({
                            variables: { eventId }
                        }).catch(error => {
                            setStatusBanner(`Error disconnecting event: ${error}`, 'error', 'circleX');
                        })
                })
            }
        )
    );

export const withDisconnectPrivateRecording = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(
            gql`
                mutation DisconnectPrivateRecording($privateRecordingId: ID!) {
                    disconnectPrivateRecording(privateRecordingId: $privateRecordingId) {
                        success
                    }
                }
            `,
            {
                props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                    disconnectPrivateRecording: privateRecordingId =>
                        mutate({
                            variables: { privateRecordingId }
                        }).catch(error => {
                            setStatusBanner(`Error disconnecting recording: ${error}`, 'error', 'circleX');
                        })
                })
            }
        )
    );

export const withEditTranscripts = () =>
    compose(
        graphql(updateEventItemMutation, {
            props: ({ mutate }) => ({
                updateEventItem: input =>
                    mutate({
                        variables: { input: { ...input } }
                    })
            })
        }),
        graphql(deleteEventItemMutation, {
            props: ({ mutate }) => ({
                deleteEventItem: itemId =>
                    mutate({
                        variables: { itemId },
                        update: proxy => {
                            const fragmentId = `ScheduledAudioCallEvent:${itemId}`;
                            const item = proxy.readFragment({
                                id: fragmentId,
                                fragment: scheduledAudioCallEventFragment
                            });
                            proxy.writeFragment({
                                id: fragmentId,
                                fragment: scheduledAudioCallEventFragment,
                                data: {
                                    ...item,
                                    status: 'deleted'
                                }
                            });
                        }
                    })
            })
        })
    );

// Pass in `subscribe` option to opt into subscription,
// it will be off by default just to make sure we don't
// hammer the server unnecessarily
export const withEventDetails = (options = {}) =>
    compose(
        withAudioCalls(
            mergeOptions(
                ({ user }) => ({
                    alias: 'withEventDetails',
                    variables: {
                        withCompany: get(options, 'withCompany', false),
                        withEventDetails: true,
                        withEventSentiment: hasPermission(user, PERMISSIONS.featureEventsTonalSentiment),
                        withPrivateRecording: get(options, 'withPrivateRecording', false),
                        withTranscript: true,
                        withUploadInfo: true
                    }
                }),
                options
            )
        ),
        withRealtime(),
        lifecycle({
            componentDidMount() {
                this.trySubscribe = () => {
                    const {
                        audioCallId,
                        audioCall,
                        shareId,
                        realtime,
                        audioCallsRefresh,
                        audioCallsFetchMore
                    } = this.props;
                    const id = get(audioCall, 'id', audioCallId);
                    const opts = mapPropsToOptions(options);
                    this.subscriptions = this.subscriptions || [];
                    this.promises = this.promises || new Set();
                    // eslint-disable-next-line no-shadow
                    const fetchEventDetails = ({ withEventDetails, withTranscript, latestOnly = true, itemId }) => {
                        const { user } = this.props;
                        const promise = makeCancelable(
                            audioCallsFetchMore({
                                query: getAudioCallsQuery('withAudioCallTranscriptFetchMore'),
                                variables: {
                                    withEventDetails: !!withEventDetails,
                                    withEventSentiment: hasPermission(user, PERMISSIONS.featureEventsTonalSentiment),
                                    withTranscript: !!withTranscript,
                                    withDifferentials: false,
                                    callIds: [id],
                                    shareId,
                                    itemId,
                                    afterItemId:
                                        withTranscript && latestOnly
                                            ? get(
                                                  maxBy(get(this.props, 'audioCall.events', []), e => e.id),
                                                  'id'
                                              )
                                            : undefined
                                },
                                updateQuery: (
                                    { audioCalls: prevAudioCalls },
                                    { fetchMoreResult: { audioCalls: newAudioCalls } }
                                ) => {
                                    const prevCall = prevAudioCalls[0];
                                    const newCall = newAudioCalls[0];
                                    return {
                                        audioCalls: [
                                            {
                                                ...prevCall,
                                                ...newCall,
                                                ...(withTranscript && (latestOnly || itemId)
                                                    ? { events: mergeEvents(prevCall.events, newCall.events) }
                                                    : {})
                                            }
                                        ]
                                    };
                                }
                            })
                        );

                        // Add this promise to the list, and chain one more on to clean
                        // it up form the tracking list
                        this.promises.add(promise);
                        promise.finally(() => {
                            this.promises.delete(promise);
                        });
                    };

                    if (!this.eventSubscriptions && id && opts.subscribe) {
                        // Listen for changes to this particular call or any of it's events
                        this.subscriptions = this.subscriptions.concat([
                            realtime.subscribe(`scheduled_audio_call_${id}_changes`, 'modified', () =>
                                fetchEventDetails({ withEventDetails: true })
                            ),
                            realtime.subscribe(`scheduled_audio_call_${id}_changes`, 'refresh', () =>
                                fetchEventDetails({ withTranscript: true, latestOnly: false })
                            ),
                            realtime.subscribe(`scheduled_audio_call_${id}_events_changes`, 'modified', () =>
                                fetchEventDetails({ withTranscript: true })
                            ),
                            realtime.subscribe(
                                `scheduled_audio_call_${id}_events_changes`,
                                'event_item_edit',
                                ({ item_id: itemId }) =>
                                    fetchEventDetails({ withTranscript: true, latestOnly: false, itemId })
                            ),
                            realtime.subscribe(
                                `scheduled_audio_call_${id}_events_changes`,
                                'event_item_delete',
                                ({ item_id: itemId }) =>
                                    fetchEventDetails({ withTranscript: true, latestOnly: false, itemId })
                            ),
                            // Refetch event details once a private recording's audio upload has finished transcribing
                            realtime.subscribe(`process_upload_${id}_changes`, 'finished', () => {
                                if (audioCallsRefresh) {
                                    audioCallsRefresh();
                                }
                            }),
                            // Private recording audio upload progress
                            realtime.subscribe(
                                `process_upload_${id}_changes`,
                                'status_update',
                                ({ pct_complete: percentComplete, status }) => {
                                    this.setState(
                                        {
                                            uploadPercentComplete: percentComplete,
                                            uploadStatus: status
                                        },
                                        () => {
                                            // If we're getting upload progress but isUploading is still false,
                                            // refetch to update the event
                                            if (!get(this.props, 'audioCall.isUploading') && audioCallsRefresh) {
                                                audioCallsRefresh();
                                            }
                                        }
                                    );
                                }
                            )
                        ]);
                        this.eventSubscriptions = true;
                    }

                    const { user } = this.props;
                    if (!this.userSubscriptions && user && user.pusherToken && opts.subscribe) {
                        this.subscriptions = this.subscriptions.concat([
                            realtime.subscribe(
                                `user_${user.pusherToken}`,
                                'event_term_match',
                                ({ event_id: eventId }) => {
                                    if (String(eventId) === String(id)) {
                                        fetchEventDetails({ withEventDetails: true });
                                    }
                                }
                            )
                        ]);
                        this.userSubscriptions = true;
                    }

                    if (this.subscriptions.length && !this.fetchInterval) {
                        this.fetchInterval = setInterval(() => {
                            if (!realtime.isConnected() || this.subscriptions.some(ch => !ch.isSubscribed())) {
                                fetchEventDetails({
                                    withEventDetails: true,
                                    withTranscript: true
                                });
                            }
                        }, 5000);
                    }
                };
                this.trySubscribe();
            },
            componentDidUpdate() {
                this.trySubscribe();
            },

            componentWillUnmount() {
                if (this.subscriptions) {
                    this.subscriptions.forEach(s => s.unsubscribe());
                }

                if (this.fetchInterval) {
                    clearInterval(this.fetchInterval);
                }

                if (this.promises) {
                    this.promises.forEach(p => p.cancel());
                }
            }
        })
    );

export const withEventLogs = (options = {}) =>
    compose(
        graphql(
            gql`
                query withEventLogs($eventId: ID!, $withCurrent: Boolean = true, $withHistory: Boolean = false) {
                    events(eventIds: [$eventId]) {
                        id
                        state {
                            current @include(if: $withCurrent) {
                                ...scheduledAudioCallState
                            }
                            history @include(if: $withHistory) {
                                ...scheduledAudioCallState
                            }
                        }
                    }
                }
                ${scheduledAudioCallStateFragment}
            `,
            {
                props: ({ data }) => ({
                    logHistory: get(data, 'events[0].state.history'),
                    logs: get(data, 'events[0].state.current'),
                    logsLoading: get(data, 'loading'),
                    refreshLogs: get(data, 'refetch')
                }),
                options: props => {
                    const opts = mapPropsToOptions(options, props);
                    const { eventId, withCurrent, withHistory } = props;
                    return {
                        fetchPolicy: opts.fetchPolicy || 'cache-and-network',
                        variables: {
                            eventId,
                            withCurrent: withCurrent === undefined ? true : withCurrent,
                            withHistory: withHistory === undefined ? false : withHistory,
                            ...(opts.variables || {})
                        }
                    };
                },
                skip: ({ eventId }) => !eventId
            }
        )
    );

const envMap = { production: 'prod', staging: 'prod', development: 'dev', local: 'local' };
// This data is stored in the media player so that we know what call is currently
// playing.
const getMetaData = memoize(props => ({
    callDate: get(props, 'audioCall.callDate'),
    callType: get(props, 'audioCall.callType'),
    equityId: get(props, 'audioCall.equity.id'),
    exchange: get(props, 'audioCall.equity.exchange.shortName'),
    id: get(props, 'audioCall.id', props.audioCallId),
    token: get(props, 'audioCall.authToken', props.callToken),
    title: get(props, 'audioCall.title'),
    ticker: get(props, 'audioCall.equity.localTicker'),
    tabId: generateTabId({ audioCallId: get(props, 'audioCall.id') }),
    isLive: get(props, 'audioCall.isLive')
}));
const hasExpired = audioCall => {
    const expiry = get(audioCall, 'callExpires');
    return expiry && new XDate(expiry) < new XDate();
};
const getMediaStorageUrl = (callId, eventType) =>
    `${eventType === 'test' ? AMP_BASE_URL_DEV : AMP_BASE_URL_PROD}/${callId}`;
const getCallMediaUrl = (opts, props) => {
    const { audioCall, audioCallId, user } = props;
    // externalAudioStreamUrl can be an empty string, so the get
    // function will not use the default value.. but it is falsy,
    // so we can use the || condition
    let archivedUrl = get(audioCall, 'externalAudioStreamUrl') || get(audioCall, 'transcriptionAudioUrl');
    const ampBaseUrl = get(audioCall, 'callType') === 'test' ? AMP_BASE_URL_DEV : AMP_BASE_URL_PROD;
    const userApiKey = get(user, 'apiKey');
    const audioStreamUri = get(audioCall, 'audioStreamUri');
    const isOver = ['finished', 'archived'].includes(get(audioCall, 'transcriptionStatus'));
    // Only use the proxy URL if we have an archivedUrl or
    // an audioStreamUrl, so we know at least one form of audio exists
    if (userApiKey && (!!archivedUrl || !!audioStreamUri)) {
        const endpoint = get(config, 'API_ENDPOINT');
        archivedUrl = `${endpoint}/events/${audioCallId}/audio?api_key=${userApiKey}&no_trim=true`;
    }

    // force manifest to be hls *m3u8 if ios
    const userAgent = window.navigator.userAgent.toLowerCase();
    const ios = /iphone|ipod|ipad/.test(userAgent);
    if (get(audioCall, 'isLive') && ios) {
        return opts.allowLiveStream && `${ampBaseUrl}${audioStreamUri}/index.m3u8`;
    }

    // if not mobile, the original manifest (dash)
    if (get(audioCall, 'isLive') && !ios) {
        return (
            opts.allowLiveStream &&
            getMediaStorageUrl(get(audioCall, 'scheduledAudioCallId'), get(audioCall, 'callType'))
        );
    }
    if (isOver && opts.allowLiveStream && !archivedUrl && audioStreamUri) {
        // Eventually the replace() can be removed, but currently the AMP service is still returning
        // HLS files back for the URI so we need to make sure to remove that so it's just
        // /eventId/streamGuid
        return `${ampBaseUrl}${audioStreamUri.replace('/index.m3u8', '')}`;
    }
    return archivedUrl;
};
export const withEventMediaPlayer = (options = {}) =>
    compose(
        graphql(
            gql`
                query withEventMediaPlayer($eventId: ID, $shareId: ID) {
                    audioCalls: events(eventIds: [$eventId], shareId: $shareId) {
                        authToken
                        audioStreamUri
                        callDate
                        callProvider
                        callType
                        conferenceNumber
                        conferencePin
                        eventId
                        externalAudioStreamUrl
                        firstTranscriptItemStartMs
                        id
                        processingAudio
                        scheduledAudioCallId
                        siftTokens
                        title
                        transcriptionAudioUrl
                        isLive
                        transcriptionStatus
                        webcastStatus
                        equity {
                            id
                            localTicker
                            exchange {
                                id
                                shortName
                            }
                        }
                    }
                }
            `,
            {
                props: ({ data }) => ({
                    audioCallsError: data.error,
                    audioCallsLoading: !!data.loading,
                    audioCall: data.audioCalls && data.audioCalls.length === 1 ? data.audioCalls[0] : null
                }),
                skip: props => (!props.audioCallId && !props.shareId) || (props.siftToken && props.callToken),
                options: ({ audioCallId, eventId, shareId }) => ({
                    fetchPolicy: 'cache-first',
                    context: { batchKey: 'withEventMediaPlayer' },
                    variables: {
                        eventId: audioCallId || eventId,
                        shareId: audioCallId || eventId ? undefined : shareId
                    }
                })
            }
        ),
        withTrackUserActivity(),
        withUser(),
        lifecycle({
            componentDidMount() {
                this.loadAudio = () => {
                    const opts = mapPropsToOptions(options, this.props);
                    if (this.mediaPlayer && opts.loadAudio) {
                        const { user, isLive } = this.props;
                        /**
                         * We were originally using SAC's transcriptionAudioOffsetSeconds here,
                         * but we have since deprecated that field - it was causing alignment issues when set
                         * much higher than 0, particularly with published transcripts.
                         * We want to load the full audio in the player, but when viewing a transcript, we need to
                         * auto-seek to the first transcript segment's startMs.
                         * This skips the opening mambo jumbo (e.g. conversing with the operator), and
                         * helps keep the live and published transcripts aligned with audio.
                         */
                        const offset = 0;
                        // Only get the media url when the user is loaded, since we may need to use the apiKey
                        if (user || isLive) {
                            const callMediaUrl = getCallMediaUrl(opts, this.props);
                            if (callMediaUrl) {
                                this.mediaPlayer.load(callMediaUrl, offset, getMetaData(this.props)).catch(() => {});
                            }
                        }
                    }
                };

                this.setMediaValues = () => {
                    if (this.mediaPlayer) {
                        const { audioCall } = this.props;
                        const callDate = get(audioCall, 'callDate');
                        const callType = get(audioCall, 'callType');
                        const isLive = get(audioCall, 'isLive');
                        this.mediaPlayer.startTime =
                            isLive && callType === 'custom'
                                ? get(audioCall, 'transcriptionConnectedDate') || callDate
                                : callDate;
                    }
                };

                this.register = () => {
                    const { audioCallsLoading, audioCallId, audioCall } = this.props;
                    if (!this.mediaPlayer && !audioCallsLoading && get(audioCall, 'id', audioCallId)) {
                        const opts = {
                            trackDetails: true,
                            ...mapPropsToOptions(options, this.props)
                        };
                        this.mediaPlayer = MediaPlayer.getPlayer(get(audioCall, 'id', audioCallId));
                        this.onUpdate = () => {
                            this.setState({
                                mediaPlayer: {
                                    ...this.mediaPlayer,
                                    ...this.mediaPlayer.state
                                }
                            });
                        };
                        // Set the initial state by calling this once now.
                        this.onUpdate();
                        this.setMediaValues();
                        registerMediaEvents(this.mediaPlayer, this.onUpdate, {
                            trackDetails: opts.trackDetails,
                            trackAudio: opts.trackAudio
                        });
                    }
                };

                this.unregister = () => {
                    if (this.mediaPlayer) {
                        MediaPlayer.cleanupPlayer(this.mediaPlayer.id);
                        unregisterMediaEvents(this.mediaPlayer, this.onUpdate);
                        delete this.mediaPlayer;
                    }
                };

                this.register();
            },
            componentDidUpdate() {
                const { audioCall, audioCallId, audioCallsLoading } = this.props;

                // Any time the call id changes (And we're finished loading it),
                // unregister the existing player and register a new one for the
                // new call id.
                if (!audioCallsLoading && get(audioCall, 'id', audioCallId) !== get(this.mediaPlayer, 'id')) {
                    if (this.mediaPlayer) {
                        this.unregister();
                    }
                    if (!this.mediaPlayer) {
                        this.register();
                    }
                }
                this.setMediaValues();
            },
            componentWillUnmount() {
                this.unregister();
            }
        }),
        connect(undefined, { setStatusBanner: statusBannerFire }),
        withStateHandlers(
            {
                startTime: 0
            },
            {
                setStartTime: () => startTime => ({ startTime })
            }
        ),
        withReporting(),
        withProps(props => {
            const {
                audioCall,
                audioCallId,
                callProvider,
                callToken,
                mediaPlayer,
                reporter,
                setStatusBanner,
                siftToken,
                startTime,
                setStartTime,
                trackAudioStartActivity,
                trackAudioStopActivity,
                user,
                userLoading
            } = props;
            const opts = mapPropsToOptions(options, props);
            const status = get(audioCall, 'transcriptionStatus', '');
            const isLive = get(audioCall, 'isLive');
            let callMediaUrl;
            // Only get the media url when the user is loaded,
            // since we may need to use the apiKey
            if (user || isLive) {
                callMediaUrl = getCallMediaUrl(opts, props);
            }
            // This is the data needed to pass to the webrtc connection to authenticate
            // and identify the current call with the server.
            const useTwilio = (callProvider || get(audioCall, 'callProvider')) === 'twilio';
            const webRTCData = {
                siftToken:
                    siftToken ||
                    get(audioCall, `siftTokens.${envMap[config.AIERA_ENV]}`) ||
                    get(audioCall, 'siftTokens.prod'),
                token: callToken || get(audioCall, 'authToken'),
                call_id: get(audioCall, 'id', audioCallId),
                useTwilio
            };

            const testMic = () => {
                let promise = Promise.reject();
                if (mobileBridge.enabled()) {
                    promise = Promise.resolve();
                } else if (navigator && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                    promise = navigator.mediaDevices.getUserMedia({ audio: true });
                }

                return promise.catch(e => {
                    setStatusBanner('You must enable microphone permission for this call', 'error', 'circleX');
                    throw e;
                });
            };

            // Twilio asks for mic access no matter what, so if we know we're only going to listen
            // overwrite the function to get the mic to return a fake stream so that even if the user
            // blocked mic access they can still listen. After it's done, reset the browser function.
            const fakeUserMedia = () => {
                const userMediaPaths = [
                    'mediaDevices.getUserMedia',
                    'webkitGetUserMedia',
                    'mozGetUserMedia',
                    'getUserMedia'
                ];
                const userMediaPath = userMediaPaths.find(path => get(navigator, path)) || 'getUserMedia';
                const oldUserMedia = get(navigator, userMediaPath);
                set(navigator, userMediaPath, () => {
                    const context = new AudioContext();
                    const destination = context.createMediaStreamDestination();
                    set(navigator, userMediaPath, oldUserMedia);
                    return Promise.resolve(destination.stream);
                });
                return Promise.resolve();
            };

            // Override some of the media player functions so that we call them with all the needed
            // args since it's a bit complicated to add all this in each container/ui component that
            // uses it.
            //
            // IF DATA IS OVERRIDDEN BY A CLICK EVENT
            // IT WILL BREAK THE WEBRTC PLAYING AUDIO
            const listen = (data = {}) => {
                trackAudioStartActivity(audioCallId);
                return new Promise((resolve, reject) => {
                    /**
                     * We were originally using SAC's transcriptionAudioOffsetSeconds here,
                     * but we have since deprecated that field - it was causing alignment issues when set
                     * much higher than 0, particularly with published transcripts.
                     * We want to load the full audio in the player, but when viewing a transcript, we need to
                     * auto-seek to the first transcript segment's startMs.
                     * This skips the opening mambo jumbo (e.g. conversing with the operator), and
                     * helps keep the live and published transcripts aligned with audio.
                     */
                    const offset = 0;
                    const ampBaseUrl = get(audioCall, 'callType') === 'test' ? AMP_BASE_URL_DEV : AMP_BASE_URL_PROD;
                    const audioStreamUri = get(audioCall, 'audioStreamUri');
                    const hlsCallMediaUrl = `${ampBaseUrl}${audioStreamUri}/index.m3u8`;
                    if (callMediaUrl) {
                        setStartTime(new Date().getTime());
                        const goLive = isLive && mediaPlayer.status !== 'paused';
                        return mediaPlayer
                            .play({
                                uri: callMediaUrl,
                                hlsUri: hlsCallMediaUrl,
                                offset,
                                metaData: getMetaData(props)
                            })
                            .then(resp => {
                                if (!resp) {
                                    return reject();
                                }
                                if (goLive) {
                                    mediaPlayer.live();
                                }
                                return true;
                            })
                            .then(resolve, reject)
                            .catch(err => {
                                // eslint-disable-next-line no-console
                                console.error(err);
                                return reject();
                            });
                    }
                    return reject();
                }).catch(error => {
                    mediaPlayer.stop();
                    // eslint-disable-next-line no-console
                    console.error(error);
                    if (isLive) {
                        return (useTwilio ? fakeUserMedia() : Promise.resolve()).then(() => {
                            return mediaPlayer.listen({ ...webRTCData, ...data }, getMetaData(props));
                        });
                    }
                    return null;
                });
            };

            return {
                mediaPlayer: {
                    ...mediaPlayer,
                    isReady: !!mediaPlayer,
                    metaData: getMetaData(props),
                    isLive,
                    canListen: !userLoading && mediaPlayer && (isLive || !!callMediaUrl),
                    listening: mediaPlayer && (mediaPlayer.status === 'listening' || mediaPlayer.status === 'muted'),
                    goLive: () =>
                        listen().then(() => {
                            mediaPlayer.live();
                        }),
                    listen,
                    pause: () => {
                        if (startTime) {
                            reporter.track(reporter.actions.click, reporter.objects.audioDuration, {
                                duration: new Date().getTime() - startTime,
                                eventId: audioCallId
                            });
                            setStartTime(0);
                        }
                        trackAudioStopActivity(audioCallId);
                        mediaPlayer.pause();
                    },
                    stop: () => {
                        if (startTime) {
                            reporter.track(reporter.actions.click, reporter.objects.audioDuration, {
                                duration: new Date().getTime() - startTime,
                                eventId: audioCallId
                            });
                            setStartTime(0);
                        }
                        trackAudioStopActivity(audioCallId);
                        mediaPlayer.stop();
                    },

                    // Call/Record used by Agent dashboard and old custom calls but no longer
                    // available to users in dashboard
                    canPlay: !!callMediaUrl,
                    canRecord:
                        get(audioCall, 'callType') === 'custom' &&
                        (!status || isLive) &&
                        get(audioCall, 'webcastStatus') !== 'stream_started' &&
                        !hasExpired(audioCall),
                    call: data => {
                        trackAudioStartActivity(audioCallId);
                        return testMic().then(() => mediaPlayer.call({ ...webRTCData, ...data }, getMetaData(props)));
                    },
                    record: (data = {}) => {
                        const confirmRequired = hasPermission(user, PERMISSIONS.featureRequestRecordingPermission);
                        let confirmed = false;

                        if (confirmRequired) {
                            // eslint-disable-next-line no-alert
                            confirmed = window.confirm(
                                'By clicking ok you acknowledge that this call is being recorded.'
                            );
                        }
                        if (!confirmRequired || confirmed) {
                            trackAudioStartActivity(audioCallId);
                            return testMic().then(() =>
                                mediaPlayer.record({ ...webRTCData, ...data }, getMetaData(props))
                            );
                        }
                        return Promise.reject(new Error('Cannot record call without user confirmation.'));
                    }
                }
            };
        })
    );

export const EventMediaPlayer = toRenderProps(withEventMediaPlayer());

export const withPartial = (options = {}) =>
    compose(
        withRealtime(),
        lifecycle({
            componentDidMount() {
                this.trySubscribe = () => {
                    const { audioCallId, realtime } = this.props;
                    const id = audioCallId;
                    const opts = mapPropsToOptions(options);
                    if (!this.subscriptions && id && opts.subscribe) {
                        this.subscriptions = [
                            realtime.subscribe(
                                `scheduled_audio_call_${id}_events_changes`,
                                'partial_transcript',
                                ({
                                    start_timestamp_ms: partialTimestampMs,
                                    pretty_transcript: prettyTranscript,
                                    transcript,
                                    index: partialIndex
                                }) => {
                                    this.setState({
                                        partialTranscript: prettyTranscript || transcript,
                                        partialTimestamp: new Date(partialTimestampMs),
                                        partialIndex
                                    });
                                }
                            ),
                            realtime.subscribe(
                                `scheduled_audio_call_${id}_events_changes`,
                                'partial_transcript_clear',
                                ({ index }) => {
                                    this.setState({ clearedPartialIndex: index });
                                }
                            )
                        ];
                    }
                };
                this.trySubscribe();
            },
            componentDidUpdate({ lastTranscriptId: prevId }) {
                const { lastTranscriptId } = this.props;
                const { partialIndex, clearedPartialIndex } = this.state || {};
                this.trySubscribe();
                if (prevId !== lastTranscriptId && partialIndex === clearedPartialIndex) {
                    this.setState({ partialTranscript: '' });
                }
            },

            componentWillUnmount() {
                if (this.subscriptions) {
                    this.subscriptions.forEach(s => s.unsubscribe());
                }
            }
        }),
        withProps(({ partialTranscript, partialTimestamp, lastEvent }) => {
            return {
                partialTranscript:
                    new Date(get(lastEvent, 'startTimestamp')) < partialTimestamp ? partialTranscript : null
            };
        })
    );

export const withUserFinancialKpis = () =>
    compose(
        connect(undefined, { setStatusBanner: statusBannerFire }),
        graphql(addUserFinancialKpiMutation, {
            props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                addFinancial: ({ equityId, financialTermKey, userId, eventId }) =>
                    mutate({
                        variables: { equityId, financialTermKey, userId, eventId, includeEvent: !!eventId }
                    })
                        .then(response => {
                            const { success } = response.data.addUserFinancialKpi;

                            if (success) {
                                setStatusBanner('Financial added successfully.');
                            } else {
                                setStatusBanner('Could not add financial', 'error', 'circleX');
                            }

                            return success;
                        })
                        .catch(error => {
                            // eslint-disable-next-line no-console
                            console.log(`Failure from addUserFinancialKpiMutation: ${error}`);
                            setStatusBanner(
                                'Something went wrong. Please reload the page and try again.',
                                'error',
                                'circleX'
                            );
                        })
            })
        }),
        graphql(replaceUserFinancialKpiMutation, {
            props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                replaceFinancial: ({ userFinancialKpiId, financialTermKey, eventId }) =>
                    mutate({
                        variables: { userFinancialKpiId, financialTermKey, eventId, includeEvent: !!eventId }
                    })
                        .then(response => {
                            const { success } = response.data.replaceUserFinancialKpi;

                            if (success) {
                                setStatusBanner('Financial replaced successfully.');
                            } else {
                                setStatusBanner('Could not replace financial', 'error', 'circleX');
                            }

                            return success;
                        })
                        .catch(error => {
                            // eslint-disable-next-line no-console
                            console.log(`Failure from replaceUserFinancialKpiMutation: ${error}`);
                            setStatusBanner(
                                'Something went wrong. Please reload the page and try again.',
                                'error',
                                'circleX'
                            );
                        })
            })
        }),
        graphql(removeUserFinancialKpiMutation, {
            props: ({ mutate, ownProps: { setStatusBanner } }) => ({
                removeFinancial: ({ userFinancialKpiId, eventId }) =>
                    mutate({
                        variables: { userFinancialKpiId, eventId, includeEvent: !!eventId }
                    })
                        .then(response => {
                            const { success } = response.data.removeUserFinancialKpi;

                            if (success) {
                                setStatusBanner(`Financial deleted successfully.`);
                            } else {
                                setStatusBanner(
                                    `Could not remove financial. Please try again later.`,
                                    'error',
                                    'circleX'
                                );
                            }
                        })
                        .catch(error => {
                            // eslint-disable-next-line no-console
                            console.log(`Failure from removeUserFinancialMutation: ${error}`);
                            setStatusBanner('Something went wrong. Please try again later.', 'error', 'circleX');
                        })
            })
        })
    );
