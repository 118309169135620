/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { Fragment, PureComponent } from 'react';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyleSheet } from 'hoc/styles';
import { PREFERENCES } from 'consts';
import { Div } from 'components/Div';
import { Text } from 'components/Text';
import { RawHTML } from 'components/RawHTML';
import { LoaderLogo } from 'components/LoaderLogo';
import { WithPreference } from 'components/WithPreference';
import { Tooltip } from 'components/Tooltip';
import { safeRegExp, toDateTimeString } from 'utils';
import { EventActionMenu } from '../EventActionMenu';
import { styleSheet } from './stylesheet';

class TranscriptEvent extends PureComponent {
    static propTypes = {
        actionMenuOpen: PropTypes.bool.isRequired,
        audioPlayed: PropTypes.bool.isRequired,
        bookmarkId: PropTypes.string,
        callType: PropTypes.string,
        color: PropTypes.string.isRequired,
        editableRef: PropTypes.objectOf(PropTypes.any),
        editing: PropTypes.bool,
        eventId: PropTypes.string.isRequired,
        handleEventClick: PropTypes.func.isRequired,
        handleRef: PropTypes.func.isRequired,
        hideTimestamp: PropTypes.bool,
        highlightsFilterKey: PropTypes.string.isRequired,
        highlightsSortKey: PropTypes.string.isRequired,
        isEventOwner: PropTypes.bool,
        isPublished: PropTypes.bool.isRequired,
        isLive: PropTypes.bool.isRequired,
        isSelected: PropTypes.bool.isRequired,
        listening: PropTypes.bool.isRequired,
        lockAudioScroll: PropTypes.bool.isRequired,
        onCancelEdit: PropTypes.func,
        onDeleteTranscript: PropTypes.func,
        onEditTranscript: PropTypes.func,
        onSubmitTranscript: PropTypes.func,
        onTranscriptChange: PropTypes.func,
        onMouseEnter: PropTypes.func.isRequired,
        onMouseLeave: PropTypes.func.isRequired,
        onOpenActionMenu: PropTypes.func.isRequired,
        onCloseActionMenu: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        resultsIndex: PropTypes.number.isRequired,
        reverse: PropTypes.bool.isRequired,
        saving: PropTypes.bool,
        searchResults: PropTypes.arrayOf(PropTypes.string),
        searchTerm: PropTypes.string.isRequired,
        selectHighlightId: PropTypes.func.isRequired,
        selectedHighlightId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        showActionMenu: PropTypes.bool.isRequired,
        showTimestamps: PropTypes.bool.isRequired,
        startTimestamp: PropTypes.string,
        streamMatch: PropTypes.objectOf(PropTypes.any),
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        transcript: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    };

    static defaultProps = {
        bookmarkId: undefined,
        callType: undefined,
        editableRef: null,
        editing: false,
        hideTimestamp: false,
        isEventOwner: false,
        onCancelEdit: () => {},
        onDeleteTranscript: () => {},
        onEditTranscript: () => {},
        onSubmitTranscript: () => {},
        onTranscriptChange: () => {},
        passedStyles: {},
        saving: false,
        searchResults: null,
        selectedHighlightId: undefined,
        startTimestamp: null,
        streamMatch: null,
        transcript: null
    };

    // Split the text and insert styled spans when there are matches for the searchTerm
    getHighlightedText(eventId, text) {
        const { searchTerm, searchResults, resultsIndex, theme } = this.props;
        let highlighted = [text];

        if (text) {
            const parts = text.split(safeRegExp(searchTerm));
            const color = searchResults[resultsIndex] === eventId ? theme.colors.yellow02 : theme.colors.blue06;
            highlighted = parts.map(part => {
                if (part.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return `<span style="${`background-color: ${color}`}">${part}</span>`;
                }
                return part;
            });
        }

        return highlighted.join('');
    }

    renderEditButtons() {
        const { styles, editing, saving, onSubmitTranscript, onCancelEdit } = this.props;
        return (
            <Div styles={styles.editIcons}>
                {saving && <LoaderLogo type={2} styles={styles.savingLoader} />}
                {editing && (
                    <Fragment>
                        <Div
                            styles={[styles.editIcon, saving && styles.saving]}
                            onClick={saving ? null : onSubmitTranscript}
                        >
                            <Text uppercase>Save</Text>
                        </Div>
                        <Div styles={[styles.editIcon, saving && styles.saving]} onClick={saving ? null : onCancelEdit}>
                            <Text uppercase>Cancel</Text>
                        </Div>
                    </Fragment>
                )}
            </Div>
        );
    }

    render() {
        const {
            actionMenuOpen,
            audioPlayed,
            bookmarkId,
            callType,
            color,
            editableRef,
            editing,
            eventId,
            handleEventClick,
            handleRef,
            hideTimestamp,
            highlightsFilterKey,
            highlightsSortKey,
            isEventOwner,
            isLive,
            isPublished,
            isSelected,
            listening,
            lockAudioScroll,
            onDeleteTranscript,
            onEditTranscript,
            onMouseEnter,
            onMouseLeave,
            onOpenActionMenu,
            onCloseActionMenu,
            onTranscriptChange,
            passedStyles,
            reverse,
            searchResults,
            selectedHighlightId,
            selectHighlightId,
            showActionMenu,
            showTimestamps,
            startTimestamp,
            streamMatch,
            styles: ownStyles,
            transcript
        } = this.props;
        const styles = { ...ownStyles, ...passedStyles };
        const containerStyles = isSelected ? styles.containerSelected : styles.container;
        const timeStampStyles = isSelected ? styles.timeStampSelected : styles.timeStamp;
        const textContent = isSelected && streamMatch && streamMatch.text ? streamMatch.text : null;
        const highlightMenu = (
            <WithPreference {...PREFERENCES.eventTranscriptsOnly} value={true} defaultValue={false}>
                {transcriptsOnly => (
                    <EventActionMenu
                        doNotRender={transcriptsOnly}
                        bookmarkId={selectedHighlightId}
                        eventItemId={eventId}
                        flagEnabled
                        highlightsFilterKey={highlightsFilterKey}
                        highlightsSortKey={highlightsSortKey}
                        isEventOwner={isEventOwner}
                        isPublished={isPublished}
                        openByDefault
                        reverse={reverse}
                        styles={styles.actionMenu}
                        useSelection={!selectedHighlightId}
                    />
                )}
            </WithPreference>
        );
        let textBlock = editing ? (
            <textarea
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                onChange={onTranscriptChange}
                ref={editableRef}
                defaultValue={DOMPurify.sanitize(transcript, { ALLOWED_TAGS: [] })}
            />
        ) : (
            <RawHTML className="transcriptEvent" html={transcript} />
        );

        if (!editing && searchResults && searchResults.length > 0) {
            textBlock = <RawHTML className="transcriptEvent" html={this.getHighlightedText(eventId, transcript)} />;
        } else if (!editing && textContent) {
            textBlock = <RawHTML className="transcriptEvent" html={textContent || transcript} />;
        }

        return (
            <WithPreference {...PREFERENCES.eventTranscriptsOnly} value={true} defaultValue={false}>
                {transcriptsOnly => (
                    <Tooltip
                        isEnabled
                        xOffset={-30} // For live events
                        content={highlightMenu}
                        keepInViewport
                        hideOnScroll={false} // For live events
                        blockBackground
                        persistOnMouseExit
                        onHide={() => {
                            // For live events
                            const selection = window.getSelection();
                            const hasSelection = selection?.toString()?.length > 0;
                            if (hasSelection) {
                                selection.collapseToEnd();
                            }
                        }}
                    >
                        {({ showTooltip }) => (
                            <Div
                                styles={{
                                    ...containerStyles,
                                    ...passedStyles,
                                    color,
                                    opacity: listening && !isLive && lockAudioScroll ? (audioPlayed ? 1 : 0.5) : 1
                                }}
                                onMouseEnter={onMouseEnter}
                                onMouseLeave={onMouseLeave}
                                onMouseDown={selectHighlightId}
                                onMouseUp={e => {
                                    if (window.getSelection()?.toString()) {
                                        showTooltip(e);
                                    } else if (selectedHighlightId) {
                                        showTooltip(e);
                                    } else {
                                        handleEventClick();
                                    }
                                }}
                                ref={node => handleRef(eventId, node)}
                            >
                                {(actionMenuOpen ||
                                    (showActionMenu &&
                                        !transcriptsOnly &&
                                        !window.getSelection().toString() &&
                                        !editing)) && (
                                    <EventActionMenu
                                        bookmarkId={bookmarkId}
                                        eventItemId={eventId}
                                        eventType={callType}
                                        flagEnabled
                                        hideTooltip={onCloseActionMenu}
                                        highlight={transcript}
                                        highlightsFilterKey={highlightsFilterKey}
                                        highlightsSortKey={highlightsSortKey}
                                        isEventOwner={isEventOwner}
                                        isPublished={isPublished}
                                        onDeleteTranscript={onDeleteTranscript}
                                        onEditTranscript={() => {
                                            onEditTranscript();
                                            if (!isSelected) {
                                                handleEventClick();
                                            }
                                        }}
                                        onShow={onOpenActionMenu}
                                        onHide={onCloseActionMenu}
                                        reverse={reverse}
                                        styles={styles.hoverActionMenu}
                                    />
                                )}
                                {!hideTimestamp && showTimestamps && (
                                    <Div styles={styles.timeStampContainer}>
                                        <Text size={1} styles={timeStampStyles}>
                                            {toDateTimeString(startTimestamp, false, false, 'h:mm:ssTT')}
                                        </Text>
                                    </Div>
                                )}
                                <Div styles={styles.transcriptText}>
                                    {textBlock}
                                    {isSelected && editing && (
                                        <Div styles={styles.footer}>
                                            <Div styles={styles.flex} />
                                            {this.renderEditButtons()}
                                        </Div>
                                    )}
                                </Div>
                            </Div>
                        )}
                    </Tooltip>
                )}
            </WithPreference>
        );
    }
}

export const TranscriptEventUI = compose(withStyleSheet(styleSheet))(TranscriptEvent);
